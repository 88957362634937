<div class="base-layout">
    <app-header [goHome]="goHome" [onLogout]="onLogout.bind(this)"></app-header>
    <!-- <main *ngIf="currentRoute=='upload'" class="main-content">
        <app-upload [setUploadData]="setMainUploadData.bind(this)" [onClickNext]="OnClickNext.bind(this)" [onSkipPas]="onSkipPas.bind(this)"></app-upload>
    </main>
    <main *ngIf="currentRoute=='post-upload'" class="main-content">
        <app-post-upload [allSectionsJson]="mainUploadData" [setUploadData]="setMainUploadData.bind(this)" [onClickNext]="OnClickNext.bind(this)" [onClickGenerateReport]="handleGenerateReport.bind(this)"></app-post-upload>
    </main> -->

    <main class="main-content" *ngIf="currentRoute!='report'">
        <ng-container *ngIf="currentRoute=='upload'||currentRoute=='post-upload'">
            <router-outlet></router-outlet>
        </ng-container>

        <div class="content-wrapper">
            <app-progress-bar *ngIf="currentRoute!='upload'&&currentRoute!='post-upload'" [activeStage]="getActiveStage()" [percentageCompletion]="getPercentageCompletion()"></app-progress-bar>
            <!-- <app-prior-art-search *ngIf="currentRoute=='pas'" [patentData]="inputData" [updateData]="updateDataSameStructure.bind(this)" [updateRoute]="changeStep.bind(this)"></app-prior-art-search>
            <app-industry-analysis *ngIf="currentRoute=='industry-analysis'" [patentData]="inputData" [updateData]="updateDataSameStructure.bind(this)" [updateRoute]="changeStep.bind(this)"></app-industry-analysis>
            <app-infringement-analysis *ngIf="currentRoute=='infringement-analysis'" [patentData]="inputData" [updateData]="updateDataSameStructure.bind(this)" [updateRoute]="changeStep.bind(this)"></app-infringement-analysis>
         -->
            <!-- ngcontainer with if conditions for all three components above and a router outlet -->
            <ng-container *ngIf="currentRoute=='pas'||currentRoute=='industry-analysis'||currentRoute=='infringement-analysis'">
                <router-outlet></router-outlet>
            </ng-container>
        </div>
    </main>

    <main *ngIf="currentRoute=='report'" class="main-content">
        <app-sniff-report [industryData]="industryData" [infringementData]="infringementData" [validitySkip]="skipPas" [validityData]="pasData" [inputData]="inputData"></app-sniff-report>
    </main>
</div>