<header class="header">
    <a href="/">
        <img src="assets/images/lumenci-logo.png" alt="Logo" class="logo" />
    </a>
    <!-- <ng-container *ngIf="eta_value && eta_value>0">
        {{eta_value}}
    </ng-container> -->
    <button mat-icon-button [matMenuTriggerFor]="userMenu">
            <i class="material-icons">account_circle</i>
        </button>
    <mat-menu #userMenu="matMenu">
        <button *ngIf="showPromptManagement" mat-menu-item [routerLink]="['/app/prompt-management']">Prompt Management</button>
        <button mat-menu-item (click)="healthCheck()">Health Check</button>
        <button mat-menu-item (click)="onLogout()">Logout</button>
    </mat-menu>

</header>