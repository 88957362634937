import { Component, Input, OnInit } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { HttpService } from '../services/http.service';
import { getEtaEmitter } from '../interceptors/eta.interceptor';
import { CommonModule } from '@angular/common';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule, MatMenu, MatMenuTrigger, MatButton, MatIconButton, MatMenuItem],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {
  @Input() goHome: any;

  @Input() onLogout: any;

  eta_value:any=null;
  eta_interval:any=null;

  showPromptManagement=false;

  constructor(private httpService: HttpService, private dataService: DataService) {
    getEtaEmitter().subscribe((eta) => {
      this.eta_value=eta
      this.stopEtaTimer()
      this.startEtaTimer()

    });

    this.showPromptManagement=this.dataService.isUserAdmin();
    
  }

  ngOnInit(){
    if(this.dataService.getUsernameObservable()){
    this.dataService.getUsernameObservable().subscribe((username:any)=>{
      this.showPromptManagement=this.dataService.isUserAdmin();
    })}
  }

  startEtaTimer(){
    // reduce eta value by 1 every second
    this.eta_interval=setInterval(()=>{
      if(this.eta_value!==null||this.eta_value>0){
        this.eta_value-=1
      }
      else{
        this.stopEtaTimer();
      }
    }, 1000)
  }

  stopEtaTimer(){
    clearInterval(this.eta_interval)
  }

  goHomeFunc(){
    this.goHome()
  }

  healthCheck(){
    this.httpService.get('/health').then((response) => {
      console.log(response);
      alert('Healthy');
    }).catch((error) => {
      console.log(error);
      alert('Unhealthy');
    });
  }


}
