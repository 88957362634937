import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpClientModule} from '@angular/common/http';
import { AppConstants } from '../../constants/app.constants';
import { DataService } from './data.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginPopupComponent } from '../common/popups/login-popup/login-popup.component';

@Injectable({
  providedIn: 'root',
})
export class HttpService {

  config: any;
  serverUrl=AppConstants.SERVER_CONFIG.URL;

  constructor(
    private http: HttpClient,
    private dataService:DataService,public dialog: MatDialog
  ) {
    this.config = AppConstants.SERVER_CONFIG;
    // this.serverUrl = this.config.URL;
  }

  // POST method
  post(url: string, body = <any>{}, { params = {}, headers = {}, responseType }: { params?: any, headers?: any, responseType?: any } = {}): Promise<any> {
    console.log(this.serverUrl + url);
    return this.http
      .post(this.serverUrl + url, body, { params, headers, responseType })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // POST with form data
  postFormData(url: string, formData = <any>{}, { params = {}, headers = {} }: { params?: any, headers?: any } = {}): Promise<any> {
    return this.http
      .post(this.serverUrl + url, formData, { params, headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  postFormDataWithBody(url: string, body = <any>{}, formData = <any>{}, { params = {}, headers = {} }: { params?: any, headers?: any } = {}): Promise<any> {
    const formDataBody = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataBody.append(key, formData[key]);
    });
    formDataBody.append('body', JSON.stringify(body));
    return this.http
      .post(this.serverUrl + url, formDataBody, { params, headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // DELETE method
  delete(url: string, { params = {}, headers = {} }: { params?: any, headers?: any } = {}): Promise<any> {
    return this.http
      .delete(this.serverUrl + url, { params, headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // GET method
  get(url: string, { params = {}, headers = {}, responseType }: { params?: any, headers?: any, responseType?: any } = {}): Promise<any> {
    return this.http
      .get(this.serverUrl + url, { params, headers, responseType })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // GET third-party URL (different base URL)
  getThirdPartyUrl(url: string, { params = {}, headers = {}, responseType }: { params?: any, headers?: any, responseType?: any } = {}): Promise<any> {
    return this.http
      .get(url, { params, headers, responseType })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // PUT method
  put(url: string, body = <any>{}, { params = {}, headers = {} }: { params?: any, headers?: any } = {}): Promise<any> {
    return this.http
      .put(this.serverUrl + url, body, { params, headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // PATCH method
  patch(url: string, body = <any>{}, { params = {}, headers = {} }: { params?: any, headers?: any } = {}): Promise<any> {
    return this.http
      .patch(this.serverUrl + url, body, { params, headers })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  // Helper function to extract response data
  private extractData(res: any) {
    const body = res;
    return body || {};
  }

  // Error handler function
  handleError(error: any): Promise<any> {
    console.log('error in http service', error);
    // let status=error.status
    if (error.status === 401||error.status==0) {
      // dataService.handleUserLogout();
      // alert('logout due to session expiry');
      this.onLogout()
    }
    return Promise.reject(error.error || error);
  }
  loginUser(username: any, password: any){
    return this.post('/authenticate', {userName:username, password:password})
     .then((response:any)=>{
       console.log(response)
       let sessionId=response.sessionID
       console.log(sessionId)
       
       //  this.sessionId=sessionId
       // set username in local storage
       window.localStorage.setItem('username', username)
       this.dataService.updateUsernameObservable(username)
       this.postUserLogin(sessionId)
       // if(response.status==200){
       //   console.log( sessionId)
       //   this.postUserLogin(sessionId)
       // }
     })
   }
   userConcurrentLogoutMessage=`<b>Session Expired</b><br>
You’ve been logged out because your account was accessed from another device. If this wasn’t you, report it to the product team.`

   onLogout(logoutMessage:any=this.userConcurrentLogoutMessage){
    alert('on logout')
    window.localStorage.removeItem('loginTime')
    window.localStorage.removeItem('sessionId')
    // stop the polling
    this.openLoginDialog(logoutMessage)
  }
  openLoginDialog(logoutMessage:any=null): void {

    const openDialogs = this.dialog.openDialogs;
    if (openDialogs.length > 0) {
      return;
    }
    const dialogRef = this.dialog.open(LoginPopupComponent, {
      disableClose: true,  // Disable backdrop click to close
      width: '400px',
      height: 'auto',
      data: {
        logoutMessage: logoutMessage||null,
        onSubmit: (formValue: any, onFailure:any, onNetworkFailure:any) => {
          console.log('Form submitted', formValue);
          this.loginUser(formValue.username, formValue.password)
          .then(()=>{
            dialogRef.close();
          })
          .catch((error:any)=>{
            if(error.detail=="Invalid username or password")
            {

              onFailure()
            }
            else{
              onNetworkFailure()
            }
            console.log('error', error)
          })
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed', result);
    });
  }
  postUserLogin(sessionId:any){
    let currentTime=new Date().getTime()
    window.localStorage.setItem('loginTime', currentTime.toString())
    window.localStorage.setItem('sessionId', sessionId)
  }
}
