<app-progress-bar-loader *ngIf="isLoading" [loadingText]="loadingText" [isError]="isError"></app-progress-bar-loader>

<div *ngIf="!isLoading" class="industry-analysis-container">
    <div *ngIf="currentStage > 1" class="header-controls">
        <div class="left-controls">

            <button mat-icon-button (click)="goBack()" class="back-button">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <span class="title">{{ titleText }}</span>
        </div>
        <div class="right-controls">
            <button class="assist-button cancel-button" *ngIf="currentStage==3" (click)="toggleAssistMode()">{{assistMode?"Unassist":"Assist"}}</button>
            <button class="cancel-button" (click)="onClickCancel()">Cancel</button>
            <button class="primary-button" *ngIf="currentStage==2" (click)="proceedToTargetLinks()">Next</button>
            <button class="primary-button" *ngIf="currentStage==3" (click)="proceedToClaimChartGeneration()">Next</button>
            <button class="primary-button" *ngIf="currentStage==4" (click)="proceedToSummary()">Next</button>
            <button class="primary-button" *ngIf="currentStage==5" (click)="onFinalNext()">Finish</button>
        </div>
    </div>

    <div *ngIf="currentStage === 2" class="table-container revenue-identification">
        <table>
            <thead>
                <tr>
                    <th>S. No.</th>
                    <th>Patent Number</th>
                    <th>Current Assignee</th>
                    <th>Targeted Company</th>
                    <th>Company Revenue</th>
                    <th>Targeted Product</th>
                    <th>Product Revenue</th>
                    <th>Global Headquarters</th>
                    <th>US Headquarters</th>
                    <th>State of Incorporation</th>
                </tr>
            </thead>
            <tbody class="limited-div-container">
                <tr *ngFor="let patent of patentDetails; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                        <!-- {{ patent.patentNumber }} -->
                        <a href="https://patents.google.com/patent/{{ patent.patentNumber }}" target="_blank">
                            {{ patent.patentNumber  }}
                        </a>
                    </td>
                    <td>{{patent.currentAssignee}}</td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <div class="d-flex align-items-center justify-content-start">
                                <input type="checkbox" [checked]="getCompanyCheckboxChecked(company, patent.patentNumber)" (change)="handleCompanyCheckboxChange(company, patent.patentNumber, $event.target)"> {{ company.name }}
                            </div>
                            <ng-container *ngFor="let product of company.products;let k= index">
                                <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">

                            <div class="revenue-edit-controls">
                                <!-- create a checkbox here and in the onchange handler call function hanldeCompanyChange with params company and true/false value -->
                                <button *ngIf="assistMode" class="img-icon-button" (click)="showRevenueControls(company)">
                                <img *ngIf="company.showRevenue" src="assets/images/checkmark.png">
                                <img *ngIf="!company.showRevenue" src="assets/images/plier-icon.png">
                            </button>
                                <div>
                                    {{ company.companyInfo.Revenue.response }}
                                    <!-- <button mat-icon-button (click)="openFileUploadPopup(patent.patentNumber, '', company.name)">
                    <mat-icon>edit</mat-icon>
                    </button> -->
                                </div>
                                <ng-container *ngIf="assistMode">
                                    <button *ngIf="company.showRevenue" class="img-icon-button" (click)="openPopup(patent.patentNumber, '', company.name)">
                    <img src="assets/images/edit-link-icon.png">
                </button>
                                    <button *ngIf="company.showRevenue" class="img-icon-button" (click)="openFileUploadPopup(patent.patentNumber, '', company.name)">
                    <img src="assets/images/upload-file-icon.png">
                </button>
                                </ng-container>
                            </div>


                            <ng-container *ngFor="let product of company.products;let k= index">
                                <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                </div>
                            </ng-container>
                        </ng-container>

                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <ng-container *ngFor="let product of company.products;let ind=index">

                                <div class="d-flex align-items-center justify-content-start" [class.last-item]="ind==company.products.length-1">
                                    <input type="checkbox" [checked]="getProductCheckboxChecked(product, company, patent.patentNumber)" (change)="handleProductCheckboxChange(product, company, patent.patentNumber,  $event.target)"> {{ product.name }}
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <div class="revenue-edit-controls" *ngFor="let product of company.products;let ind=index">
                                <button *ngIf="assistMode" class="img-icon-button" (click)="showProductRevenueControls(product)">
                                <img *ngIf="product.showRevenue" src="assets/images/checkmark.png">
                                <img *ngIf="!product.showRevenue" src="assets/images/plier-icon.png">
                            </button>
                                <div [class.last-item]="ind==company.products.length-1">
                                    {{ product.revenue.response }}
                                    <!-- <ng-container *ngIf="product.revenue.response">
                                        {{product.revenue?.response?.length()}}
                                    </ng-container> -->
                                </div>
                                <ng-container *ngIf="assistMode">

                                    <button *ngIf="product.showRevenue" class="img-icon-button" (click)="openPopup(patent.patentNumber, product.name, company.name)">
                                    <img src="assets/images/edit-link-icon.png">
                                </button>
                                    <button *ngIf="product.showRevenue" class="img-icon-button" (click)="openFileUploadPopup(patent.patentNumber, product.name, company.name)">
                                    <img src="assets/images/upload-file-icon.png">
                                </button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies; let j=index">
                            <ng-container *ngIf="company.new!=true">
                                <div>

                                    {{ company.companyInfo["Global Headquarters"]["response"] }}
                                </div>
                                <ng-container *ngFor="let product of company.products;let i= index">
                                    <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies; let j=index">
                            <ng-container *ngIf="company.new!=true">
                                <div>

                                    {{ company.companyInfo["US Headquarters"]["response"] }}
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let product of company.products;let i= index">
                                <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <ng-container *ngIf="company.new!=true">
                                <div>

                                    {{ company.companyInfo["State of Incorporation"]["response"] }}
                                </div>
                                <ng-container *ngFor="let product of company.products;let i= index">
                                    <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                    </td>

                </tr>
            </tbody>
        </table>

    </div>
    <div *ngIf="currentStage === 3" class="table-container revenue-identification">
        <table>
            <thead>
                <tr>
                    <th>S. No.</th>
                    <th>Patent Number</th>
                    <th>Current Assignee</th>
                    <th>Targeted Company</th>
                    <th>Company Revenue</th>
                    <th>Targeted Product</th>
                    <th>Product Revenue</th>
                    <th>Global Headquarters</th>
                    <th>US Headquarters</th>
                    <th>State of Incorporation</th>
                    <th>Technical Documentation Links</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let patent of patentDetails; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                        <!-- {{ patent.patentNumber }} -->
                        <a href="https://patents.google.com/patent/{{ patent.patentNumber }}" target="_blank">
                            {{ patent.patentNumber  }}
                        </a>
                    </td>
                    <td>{{patent.currentAssignee}}</td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <div>
                                {{ company.name }}
                            </div>
                            <ng-container *ngFor="let product of company.products;let k= index">
                                <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">

                            <div class="revenue-edit-controls">
                                <!-- <button *ngIf="assistMode" class="img-icon-button" (click)="showRevenueControls(company)">
                                <img *ngIf="company.showRevenue" src="assets/images/checkmark.png">
                                <img *ngIf="!company.showRevenue" src="assets/images/plier-icon.png">
                            </button> -->
                                <div>
                                    {{ company.companyInfo.Revenue.response }}
                                    <!-- <button mat-icon-button (click)="openFileUploadPopup(patent.patentNumber, '', company.name)">
                    <mat-icon>edit</mat-icon>
                    </button> -->
                                </div>
                                <ng-container *ngIf="assistMode">
                                    <button *ngIf="company.showRevenue" class="img-icon-button" (click)="openPopup(patent.patentNumber, '', company.name)">
                    <img src="assets/images/edit-link-icon.png">
                </button>
                                    <button *ngIf="company.showRevenue" class="img-icon-button" (click)="openFileUploadPopup(patent.patentNumber, '', company.name)">
                    <img src="assets/images/upload-file-icon.png">
                </button>
                                </ng-container>
                            </div>


                            <ng-container *ngFor="let product of company.products;let k= index">
                                <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                </div>
                            </ng-container>
                        </ng-container>

                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <ng-container *ngFor="let product of company.products;let ind=index">

                                <div [class.last-item]="ind==company.products.length-1">
                                    {{ product.name }}
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <div class="revenue-edit-controls" *ngFor="let product of company.products;let ind=index">
                                <!-- <button *ngIf="assistMode" class="img-icon-button" (click)="showProductRevenueControls(product)">
                                <img *ngIf="product.showRevenue" src="assets/images/checkmark.png">
                                <img *ngIf="!product.showRevenue" src="assets/images/plier-icon.png">
                            </button> -->
                                <div [class.last-item]="ind==company.products.length-1">
                                    {{ product.revenue.response }}
                                    <!-- <ng-container *ngIf="product.revenue.response">
                                        {{product.revenue?.response?.length()}}
                                    </ng-container> -->
                                </div>
                                <!-- <ng-container *ngIf="assistMode">

                                    <button *ngIf="product.showRevenue" class="img-icon-button" (click)="openPopup(patent.patentNumber, product.name, company.name)">
                                    <img src="assets/images/edit-link-icon.png">
                                </button>
                                    <button *ngIf="product.showRevenue" class="img-icon-button" (click)="openFileUploadPopup(patent.patentNumber, product.name, company.name)">
                                    <img src="assets/images/upload-file-icon.png">
                                </button>
                                </ng-container> -->
                            </div>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies; let j=index">
                            <ng-container *ngIf="company.new!=true">
                                <div>

                                    {{ company.companyInfo["Global Headquarters"]["response"] }}
                                </div>
                                <ng-container *ngFor="let product of company.products;let i= index">
                                    <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies; let j=index">
                            <ng-container *ngIf="company.new!=true">
                                <div>

                                    {{ company.companyInfo["US Headquarters"]["response"] }}
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let product of company.products;let i= index">
                                <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <ng-container *ngIf="company.new!=true">
                                <div>

                                    {{ company.companyInfo["State of Incorporation"]["response"] }}
                                </div>
                                <ng-container *ngFor="let product of company.products;let i= index">
                                    <div *ngIf="i!=0" [class.last-item]="i==company.products.length-1">
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <div class="revenue-edit-controls" *ngFor="let product of company.products;let ind=index">
                                <button *ngIf="assistMode" class="img-icon-button" (click)="showProductRevenueControls(product)">
                                <img *ngIf="product.showRevenue" src="assets/images/checkmark.png">
                                <img *ngIf="!product.showRevenue" src="assets/images/plier-icon.png">
                            </button>
                                <div [class.last-item]="ind==company.products.length-1">
                                    <div *ngIf="product.technicalDocumentationLinks&&product.technicalDocumentationLinks.length>0" class="product-target-link" (click)="openLinksDialog(product)">
                                        <a class="black-underline"> Links ({{product.technicalDocumentationLinks.length}})</a>
                                    </div>
                                    <!-- case where no links  -->
                                    <div *ngIf="!product.technicalDocumentationLinks||product.technicalDocumentationLinks.length==0" class="product-target-link">
                                        Unkown
                                    </div>

                                    <!-- <ng-container *ngIf="product.revenue.response">
                                        {{product.revenue?.response?.length()}}
                                    </ng-container> -->
                                </div>
                                <ng-container *ngIf="assistMode">

                                    <button *ngIf="product.showRevenue" class="img-icon-button" (click)="hanldeInputLinkUpdate(company, product)">
                                    <img src="assets/images/edit-link-icon.png">
                                </button>
                                    <button *ngIf="product.showRevenue" class="img-icon-button" (click)="handleFileLinkUpdate(company, product)">
                                    <img src="assets/images/upload-file-icon.png">
                                </button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
    <div *ngIf="currentStage === 4" class="table-container claim-chart-table">
        <div *ngFor="let claimData of claimChartData; let i=index">
            <div>

                <div class="claim-chart-iterable-heading">
                    {{claimData.patent_number}} vs. {{claimData.targeted_product}}
                    <img src="assets/images/regenerate-icon.png" (click)="regenerateClaimChart(claimData.patent_number, claimData.targeted_product)">
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Claims</th>
                            <th>Infringement</th>
                            <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="claim-chart-iterable" *ngFor="let claimElement of claimData.data; let j=index">
                            <td>
                                <div class="claim-element-td-container">
                                    {{claimElement.claim_element}}
                                </div>
                            </td>
                            <td>
                                <div class="claim-element-td-contaienr">
                                    <div class="indvidual-part-claim-element">
                                        <b>Analyst Comment: </b> {{claimElement.analyst_comment}}
                                    </div>
                                    <div class="individual-part-claim-element">
                                        <div><b>References:</b></div>
                                        <div *ngFor="let reference of claimElement.references">
                                            <div>{{reference.reference}}</div>
                                        </div>
                                    </div>
                                    <div class="individual-part-claim-element">
                                        <div><b>Sources:</b></div>
                                        <ng-container *ngFor="let reference of claimElement.references; let ind=index">
                                            <a href="{{reference.source}}" target="_blank">{{reference.source}}</a>
                                            <span *ngIf="ind!=claimElement.references.length-1">, </span>

                                        </ng-container>
                                    </div>

                                </div>
                            </td>
                            <td>
                                <div class="claim-element-td-container">
                                    <ng-container>
                                        <!-- *ngIf="j==0" -->
                                        <!-- {{claimData.score}} -->
                                        <app-validity-score [score]="claimData.score"></app-validity-score>
                                    </ng-container>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
    <div *ngIf="currentStage === 5" class="table-container revenue-identification">
        <table>
            <thead>
                <tr>
                    <th>S. No.</th>
                    <th>Patent Number</th>
                    <th>Targeted Company</th>
                    <th>Targeted Product</th>
                    <th>Infringement Score</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let patent of patentDetails; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                        <!-- {{ patent.patentNumber }} -->
                        <a href="https://patents.google.com/patent/{{ patent.patentNumber }}" target="_blank">
                            {{ patent.patentNumber  }}
                        </a>
                    </td>
                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <div>
                                {{ company.name }}
                            </div>
                            <ng-container *ngFor="let product of company.products;let k= index">
                                <div *ngIf="k!=0" [class.last-item]="k==company.products.length-1">
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>

                    <td>
                        <ng-container *ngFor="let company of patent.companies">
                            <ng-container *ngFor="let product of company.products;let ind=index">

                                <div [class.last-item]="ind==company.products.length-1">
                                    {{ product.name }}
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <!-- {{patentInfringementScoreData[patent.patentNumber]}} -->
                        <app-validity-score [score]="patentInfringementScoreData[patent.patentNumber]"></app-validity-score>
                    </td>

                </tr>
            </tbody>
        </table>

    </div>
</div>