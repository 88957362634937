<app-progress-bar-loader *ngIf="isLoading" [isError]="networkError" [loadingText]="loadingText" [override_Eta]="overrideEtaValue"></app-progress-bar-loader>

<div *ngIf="!isLoading" class="industry-analysis-container">
    <div *ngIf="currentStage > 1" class="header-controls">
        <div class="left-controls">

            <button mat-icon-button class="back-button" (click)="goBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <span class="title">{{ titleText }}</span>
        </div>
        <div class="right-controls">
            <!-- <button class="assist-button cancel-button" *ngIf="currentStage==3" (click)="toggleAssistMode()">{{assistMode?"Unassist":"Assist"}}</button> -->
            <button class="cancel-button" (click)="onClickCancel()">Cancel</button>
            <button class="primary-button" *ngIf="currentStage==2" (click)="toggleSearchStrings()">Keyword Strings</button>
            <button class="primary-button" *ngIf="currentStage==2" (click)="proceedToClaimFeatureMatrix()">Next</button>
            <button class="primary-button" *ngIf="currentStage==3" (click)="proceedToSummary()">Next</button>
            <button class="primary-button" *ngIf="currentStage==4" (click)="proceedToIndustryAnalysis()">Next</button>
        </div>
    </div>

    <div *ngIf="currentStage === 2" class="table-container revenue-identification references-step">
        <div class="references-wrapper">
            <div *ngFor="let patent of patentDetails; let ind = index" class="references-parent-container">
                <div class="reference-div-header">
                    <div class="reference-patent-title">
                        {{ind+1}}) <a href="https://patents.google.com/patent/{{ patent.patentNumber }}" target="_blank"><u>{{patent.patentNumber}}</u></a>
                    </div>
                    <button class="reference-arrow" [class.arrow-closed]="!referenceDivOpenMap(patent.patentNumber, ind)" (click)="toggleReferenceDiv(patent.patentNumber)">
                        <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 11.5L11 2.5L2 11.5" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                    </button>
                </div>
                <div class="reference-div-body" [class.body-open]="referenceDivOpenMap(patent.patentNumber, ind)">
                    <div *ngFor="let reference of patentReferenceTierMap[patent.patentNumber]; let j=index" class="reference-tier">
                        <div class="tier-name">
                            Tier {{reference.tier}}
                        </div>
                        <div class="tier-patent-container">

                            <div class="tier-patent">
                                {{j+1}}. <a href="https://patents.google.com/patent/{{ reference.patentNumber }}" target="_blank"><u>{{reference.patentNumber}}</u></a>
                            </div>
                            <div class="tier-patent-description">
                                {{reference.description||"No Description"}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="patentReferenceTierMap[patent.patentNumber].length==0" class="no-reference-table reference-tier ">
                        No Reference Patent Found
                    </div>
                </div>
            </div>
        </div>
        <div class="keywords-wrapper" *ngIf="showSearchStrings">
            <div class="keywords-title-wrapper">
                <div class="keywords-title">View Search Strings</div>
                <div class="keywords-add-button" (click)="keywordItems.push('')">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 8V16" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 12H16" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                </div>
            </div>
            <div class="keywords-data-container">
                <div *ngFor="let keyword of keywordItems; let ind=index" class="keyword-item">
                    <div class="keyword-number">
                        String {{ind+1}}
                        <!-- create a button to delete string -->
                        <span class="cursor-pointer delete-keyword" (click)="deleteKeyword(ind)">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.25 4.5H3.75H15.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72065 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.5 8.25V12.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.5 8.25V12.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                        </span>
                    </div>
                    <input class="keyword-content" [(ngModel)]="keywordItems[ind]">
                </div>
            </div>
            <div class="keyword-footer">
                <button class="primary-button" (click)="regenerateKeywordResults()">Regenerate Results</button>
            </div>
        </div>
    </div>
    <div *ngIf="currentStage === 3" class="table-container revenue-identification">
        <div class="references-wrapper">
            <div *ngFor="let patent of patentDetails; let ind = index" class="references-parent-container">
                <div class="reference-div-header">
                    <div class="reference-patent-title">
                        {{ind+1}}) <a href="https://patents.google.com/patent/{{ patent.patentNumber }}" target="_blank"><u>{{patent.patentNumber}}</u></a>
                    </div>
                    <button class="reference-arrow" [class.arrow-closed]="!referenceDivOpenMap(patent.patentNumber, ind)" (click)="toggleReferenceDiv(patent.patentNumber)">
                        <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 11.5L11 2.5L2 11.5" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                    </button>
                </div>
                <div class="reference-div-body" [class.body-open]="referenceDivOpenMap(patent.patentNumber, ind)">
                    <div *ngIf="referenceTableMap[patent.patentNumber]&&referenceTableMap[patent.patentNumber].length==0" class="no-reference-table reference-tier">
                        No Reference Patent Found
                    </div>
                    <div *ngFor="let referenceTable of referenceTableMap[patent.patentNumber]; let j=index" class="reference-table-container">
                        <div class="reference-table-header-container">
                            <div class="reference-table-header">
                                Reference {{j+1}} {{referenceTable.referenceTitle}}
                            </div>
                            <button class="reference-table-arrow" [class.arrow-closed]="!referenceTableOpenMap(patent.patentNumber, j, j)" (click)="toggleReferenceTable(patent.patentNumber, j)">
                                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 11.5L11 2.5L2 11.5" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    
                            </button>
                        </div>
                        <div class="reference-table-body" [class.body-open]="!referenceTableOpenMap(patent.patentNumber, j, j)">
                            <table>
                                <thead>
                                    <tr>
                                        <th>S. No.</th>
                                        <th>Independent Claim Elements</th>
                                        <th>Mapping with <a href="https://patents.google.com/patent/{{patent.patentNumber}}" target="_blank"><u>{{patent.patentNumber}}</u></a></th>
                                        <th>AI Comment</th>
                                        <th>Validity Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let claim of referenceTable.claimElements; let k=index">
                                        <td>{{k+1}}</td>
                                        <td>
                                            <div class="claim-element-td-container">
                                                {{claim.claim_element}}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="claim-element-td-container">
                                                {{claim.overlap}}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="claim-element-td-container">
                                                {{claim.ai_comment}}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="claim-element-td-container" *ngIf="k==0">
                                                <!-- {{claim.validity_score||0}} -->
                                                <app-validity-score [score]="claim.score||0"></app-validity-score>
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <td>1</td>
                                        <td>
                                            <div class="claim-element-td-container">
                                                A method for providing a positioning-assisting signal to a positioning engine, the method comprising:
                                            </div>
                                        </td>
                                        <td>
                                            Full Overlap
                                        </td>
                                        <td>
                                            the input sources comprising: one or more cameras, one or more microphones.
                                        </td>
                                        <td>
                                            7
                                        </td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>

    <div *ngIf="currentStage === 4" class="table-container revenue-identification">
        <table>
            <thead>
                <tr>
                    <th>S. No.</th>
                    <th>Patent Number</th>
                    <th>Title</th>
                    <th>Current Assignee</th>
                    <th>Validity Score</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let patent of summaryData; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                        <a href="https://patents.google.com/patent/{{ patent.patentNumber }}" target="_blank">
                            {{ patent.patentNumber  }}
                        </a>
                        <!-- {{ patent.patentNumber }} -->
                    </td>
                    <td>{{patent.title}}</td>
                    <td>{{patent.assignee}}</td>
                    <td>
                        <app-validity-score [score]="patent.score"></app-validity-score>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</div>