<div [class.loader-error]="isError" class="loader-container">

    <div *ngIf="!isError" class="loader-text">{{ loadingText }}</div>
    <div *ngIf="isError" class="loader-text loading-text-error">Something went wrong, please try again later...</div>
    <div class="loader-progress-bar-background" *ngIf="percentageCompletion!=-1||progress_value">

        <div class="loader-progress-bar" [ngStyle]="{ 'width.%': progress_determined_value }"></div>
    </div>
    <div class="eta-progress-container" *ngIf="percentageCompletion!=-1||progress_value">
        <div class="eta-text">{{etaKeyword}} {{refined_eta_value}}</div>
        <ng-container>
            {{progress_determined_value}}%
        </ng-container>
    </div>
    <mat-spinner *ngIf="percentageCompletion==-1&&!progress_value&&!isError" [color]="'primary'" [diameter]="50"></mat-spinner>
</div>