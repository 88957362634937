<div class="header-controls">
    <div class="left-controls">

        <button mat-icon-button (click)="goBack()" class="back-button">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <span class="title">Prompt Management</span>
    </div>
    <div class="right-controls">
        <ng-container *ngIf="selectedGroup">
            <span class="additional-text">
                Last Updated: {{ selectedGroup[0]?.lastUpdatedTime }}
            </span>
        </ng-container>
    </div>
</div>
<div *ngFor="let group of promptGroups" class="prompt-group">
    <div class="group-header" (click)="toggleGroup(group)">
        {{ getHeadingFormatted(group.module_name) }}
        <ng-container *ngIf="group.expanded">
            <svg class="rotated" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9L12 15L18 9" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
        </ng-container>
        <ng-container *ngIf="!group.expanded">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9L12 15L18 9" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
        </ng-container>
    </div>
    <div *ngIf="group.expanded" class="prompts">
        <div *ngFor="let prompt of group.prompts" class="prompt">
            <div class="prompt-header">
                <span>{{ getPromptNameFormatted(prompt.promptName) }}</span>
                <div class="prompt-buttons">
                    <button *ngIf="!prompt.editing" (click)="editPrompt(prompt)" class="edit"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                    <button *ngIf="!prompt.editing" (click)="copyPrompt(prompt)" class="copy"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </button>
                    <button *ngIf="prompt.editing" (click)="cancelEdit(prompt)" class="cancel">Cancel</button>
                    <button *ngIf="prompt.editing" (click)="savePrompt(prompt)" class="save">Save</button>
                </div>
            </div>
            <div *ngIf="!prompt.editing" class="prompt-content">
                <p>{{ prompt.promptValue }}</p>
            </div>
            <div *ngIf="prompt.editing" class="prompt-edit">
                <textarea [(ngModel)]="prompt.promptValue"></textarea>
            </div>
        </div>
    </div>
</div>