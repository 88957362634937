import { Component, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';  // Import HttpClientModule and HttpClient
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http'; // Import HttpClientModule
import * as XLSX from 'xlsx';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ClaimsDialogComponent } from '../claims-dialog/claims-dialog.component';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-post-upload',
  standalone: true,
  imports:[
    MatTooltipModule,
    MatButtonModule,
    CommonModule,
    HttpClientModule,  // Add HttpClientModule here
    // Other necessary modules
  ],
  templateUrl: './post-upload.component.html',
  styleUrl: './post-upload.component.css'
})
export class PostUploadComponent {
 
    @ViewChild('fileInput') fileInput: ElementRef | undefined;
  
    @Input () setUploadData:any;
  
    @Input () onClickNext:any;
  
    currentStep: number = 1;  // Initially in Step 1
    fileName: string = '';
    fileSize: string = '';
    progress: number = 0;  // To track progress of file parsing (Step 2) and API call (Step 3)
    isError: boolean = false;
    isSuccess: boolean = false;
    step2Error: boolean = false;
    step2ErrorText: string = '';
    patentNumbers: string[] = [];  // To store parsed patent numbers
    extracting: boolean = false;  // To track the loading state for API call (Step 3)
    extractionComplete: boolean = false; // To track API call completion
  
    allowedFileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'application/vnd.ms-excel.sheet.macroEnabled.12'];
    @Input () allSectionsJson: any[] = [];  // This will hold the API response
    @Input () onClickGenerateReport:any;
  
    patentNumberRegex=/^(?:([A-Z]{2})|[A-Z])(\d{6,14})|(?:([A-Z]\d?)|[A-Z]{2})$/
  
    isExcelFileIcon:boolean=true;
  
    constructor(private http: HttpClient, public dialog: MatDialog, public dataService:DataService) {

      this.allSectionsJson= this.dataService.mainUploadData;
      this.onClickGenerateReport=this.dataService.onClickGenerateReport.bind(this.dataService);
      // this.updateData=this.dataService.updateDataSameStructure.bind(this.dataService);
      // this.updateRoute=this.dataService.updateRouteObservable.bind(this.dataService);
    }
    
    // Trigger the file input click (Step 1)
    triggerFileUpload() {
      this.fileInput?.nativeElement.click();
    }

    generateReport(){
      this.onClickGenerateReport();
    }
  
    // Function to handle file selection (Step 1 -> Step 2)
    onFileSelect(event: any) {
      const file = event.target.files[0];
      if(!this.isExcelFile(file)){
        this.isExcelFileIcon=false;
      }
      else{
        this.isExcelFileIcon=true;
      }
      if (file){//} && this.isExcelFile(file)) {
        this.fileName = file.name;
        this.fileSize = this.formatFileSize(file.size);  // Format and store file size
        this.goToStep(2);
        this.parseFile(file);  // Perform file parsing in step 2
      } else {
        this.showError('file type error');
      }
    }
  
  
    onFileDrop(event: any) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if(!this.isExcelFile(file)){
        this.isExcelFileIcon=false;
      }
      else{
        this.isExcelFileIcon=true;
      }
      if (file){//} && this.isExcelFile(file)) {
        this.fileName = file.name;
        this.fileSize = this.formatFileSize(file.size);  // Format and store file size
        this.goToStep(2);
        this.parseFile(file);  // Perform file parsing in step 2
      } else {
        
        this.showError('file type error');
      }
    }
  
    onDragOver(event: any) {
      event.preventDefault();
    }
  
    onDragLeave(event: any) {
      event.preventDefault();
    }
  
    // Check if the file is an Excel file
    isExcelFile(file: File): boolean {
      return this.allowedFileTypes.includes(file.type);
    }
  
    // Format file size for display
    formatFileSize(size: number): string {
      if (size < 1024) {
        return size + ' bytes';
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + ' KB';
      } else {
        return (size / (1024 * 1024)).toFixed(2) + ' MB';
      }
    }
  
    // Parse the Excel file (Step 2 - show progress bar while parsing)
    parseFile(file: File) {
      this.progress = 0;
      this.isError = false;
      this.isSuccess = false;
      this.step2Error = false;
      this.patentNumbers = [];
  
  
      // Simulate progress for file parsing
      const parsingInterval = setInterval(() => {
        if (this.progress < 100) {
          this.progress += 20; // Increment by 20% every time
        } else {
          clearInterval(parsingInterval);
        }
      }, 500); // Parsing progress every 500ms
  
      const reader = new FileReader();
      reader.onload = (e: any) => {
        try {
          if(!this.isExcelFile(file)){
            this.showError('file type error');
            return;
          }
          
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const excelData:any = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
          // CHECK IF THE EXCEL IS BLANK
          if (excelData.length == 0) {
            this.showError('file blank error');
            return;
          }
          
          // check if number of columns exceeds 1
          if (excelData[0].length > 1) {
            this.showError('file parsing error');
            return;
          }
  
          if (excelData.length > 1) {
            // Extract patent numbers from the second row onward
            // let _patentNumbers = excelData.slice(1).map((row: any) => row[0].toString());
            let _patentNumbers = excelData.slice(1).map((row: any) => {console.log(row, row&&row[0] );return row[0] ? row[0].toString() : null}).filter((row:any)=>row).map((row:any)=>row.trim());
            console.log(_patentNumbers);
            // check for invalid patent numbers
            for (let i=0; i<_patentNumbers.length; i++){
              if (!_patentNumbers[i].trim().match(this.patentNumberRegex)){
                console.log('invalid', _patentNumbers[i]);
                this.showError('invalid patent number');
                return;
              }
              else
              {
                console.log('matched');
              }
            }
            this.patentNumbers = _patentNumbers;
            // check for duplicate patent numbers
            let unique_patent_numbers = [...new Set(this.patentNumbers)];
            if (unique_patent_numbers.length != this.patentNumbers.length){
              this.showError('duplicate exist');
              return;
            }
            clearInterval(parsingInterval);  // Parsing is complete
            this.progress = 100;
          } else {
            this.showError('file parsing error');
          }
        } catch (error) {
          console.error(error);
          this.showError('file parsing error');
        }
      };
  
      reader.onerror = () => {
        this.showError('network error');
      };
  
      reader.readAsArrayBuffer(file);
    }
  
    // Function to be triggered when "Extract" button is clicked (Step 2 -> Step 3)
    onExtract() {
      this.goToStep(3);  // Immediately switch to Step 3
      this.startExtraction();  // Trigger the API call and progress bar for Step 3
    }
  
    sampleResponse={
      "all_sections_json": [
        {
          "Publication Number": "US1234567A",
          "Title": "Innovative Widget",
          "Priority Date": "2020-01-01",
          "Expiration Date": "2040-01-01",
          "Abstract": "An innovative widget that improves productivity by 50%.",
          "Description": "This widget is designed to increase productivity by 50% by incorporating new techniques in widget manufacturing.",
          "Claims": [
            "1. A widget comprising a base, a top, and a mechanism for improving productivity.",
            "2. The widget of claim 1, wherein the base is made of lightweight material.",
            "3. The widget of claim 2, wherein the top includes a sensor for tracking usage."
          ],
          "Patent Citations": "US9876543B, US8765432A",
          "Cited By": "US2345678A, US3456789B",
          "Similar Documents": "WO2020123456A1, EP1234567B1"
        },
        {
          "Publication Number": "US2345678B",
          "Title": "Advanced Gadget",
          "Priority Date": "2019-05-15",
          "Expiration Date": "2039-05-15",
          "Abstract": "A gadget that enhances communication capabilities across devices.",
          "Description": "This gadget uses novel antenna technology to boost signal strength and improve communication between devices over long distances.",
          "Claims": [
            "1. A gadget comprising an antenna, a power supply, and a mechanism for improving communication.",
            "2. The gadget of claim 1, wherein the antenna is omni-directional.",
            "3. The gadget of claim 2, further comprising a battery backup for extended use."
          ],
          "Patent Citations": "US6543210A, US7654321B",
          "Cited By": "US4567890A, US5678901B",
          "Similar Documents": "WO2020567890A1, EP7654321B1"
        }
      ]
    }
    
  
    startExtraction() {
      this.extracting = true;  // Set extracting flag to show progress
      this.progress = 0;  // Reset progress
  
      // Simulate progress for the API call
      const extractionInterval = setInterval(() => {
        if (this.progress < 100) {
          this.progress += 10; // Increment by 10% every 200ms
        } else {
          clearInterval(extractionInterval);
        }
      }, 200); // Simulate API call progress
  
      // Simulate API call with HttpClient (send extracted patent numbers)
      this.http.post('/scraping-patent-list', { patent_number_list: this.patentNumbers })
      .subscribe(
        (response: any) => {
          let str_array=response.all_patents;
          // parse all the individual array items into json and store in all_sections_json
          let all_patents=[];
          for (let i=0; i<str_array.length; i++){
            all_patents.push(JSON.parse(str_array[i]));
          }
          all_patents.forEach((patent:any)=>{
            let patentClaimJson=patent.ClaimJson;
            console.log(patent);
              patentClaimJson= patentClaimJson.substring(patentClaimJson.indexOf('{'));
            patentClaimJson=patentClaimJson.substring(0, patentClaimJson.lastIndexOf('}')+1);
            patentClaimJson=JSON.parse(patentClaimJson);
            patent['Independent Claims']=patentClaimJson['independent_claims'];
            patent['Dependent Claims']=patentClaimJson['dependent_claims'];
          });
          this.handleSuccess(all_patents);  // Pass API response
        },
        error => {
          clearInterval(extractionInterval);  // Stop the progress bar
          this.progress = 100;  // Set progress to 100 for a complete state
  
          this.handleError();  // On error, display error message
          // this.handleSuccess(this.sampleResponse.all_sections_json);  // Pass API
        }
        );
    }
  
    // Step 3: Handle successful API call
    handleSuccess(apiResponse: any[]) {
      this.extracting = false;
      this.extractionComplete = true;
      this.allSectionsJson = apiResponse;  // Store the API response for Step 4
      this.setUploadData(this.allSectionsJson);
      setTimeout(() => {
        this.goToStep(4);  // Automatically move to Step 4 after 2 seconds
      }, 2000);
    }
  
    // Handle error scenario (Step 3)
    handleError() {
      this.extracting = false;
      this.isError = true;
      this.step2ErrorText = 'Network Error<br> Please try again later.';
    }
  
    // Step 4: Open the claims in a dialog (popup)
    openClaimsDialog(claims: string[]) {
      this.dialog.open(ClaimsDialogComponent, {
        data: { claims }
      });
    }
  
    goToStep(step: number) {
      this.currentStep = step;
    }
  
    onBack() {
      // reset all the values and go back to step 1
      this.fileName = '';
      this.fileSize = '';
      this.progress = 0;
      this.isError = false;
      this.isSuccess = false;
      this.step2Error = false;
      this.step2ErrorText = '';
      this.patentNumbers = [];
      this.extracting = false;
      this.extractionComplete = false;
      this.goToStep(1);
    }
  
  
    // Show error with appropriate error message (Step 2)
    showError(errorType: string) {
      this.isError = true;
      this.step2Error = true;
      this.step2ErrorText = this.getErrorMessage(errorType);
      this.progress = 100; // Set progress to 100 for a complete state
    }
  
    // Get the error message based on error type
    getErrorMessage(errorType: string): string {
      switch (errorType) {
        case 'network error':
          return 'Connection Issue, please try again later.';
        case 'file parsing error':
          return 'File Parsing Error: Unable to parse the file. Please ensure the file format is correct.';
        case 'file type error':
          return 'File not supported, please upload .xls or .xlsx files only.';
        case 'invalid patent number':
          return 'Invalid or duplicate patent number found, Please upload with valid patents numbers.';
        case 'file blank error':
          return 'File is blank, please upload a valid file.';
        case 'duplicate exist':
          return 'Invalid or duplicate patent number found, Please upload with valid patents numbers.';
        default:
          return 'An unknown error occurred.';
      }
    }
  
  }
  