<!-- Step 1: Upload -->
<div *ngIf="currentStep == 4" class="header-controls">

    <div class="right-controls">
        <button class="next-button" (click)="openDialog()">Excavate Portfolio</button>
    </div>
</div>

<div *ngIf="currentStep === 1" class="upload-section">
    <h1 class="infringement-upload-title">Welcome to Infringement Detector</h1>
    <div class="upload-box">
        <div class="first-header">Upload Patent File</div>
        <div class="upload-content" (drop)="onFileDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
            <!-- <i class="material-icons">cloud_upload</i> -->
            <img src="assets/images/upload-icon.png" alt="Upload Icon" class="upload-icon" />
            <p>Click to upload <br> or <br> Drag and drop</p>
            <input #fileInput type="file" (change)="onFileSelect($event)" style="display: none;" />
            <button class="upload-button" mat-button color="primary" (click)="triggerFileUpload()">Upload</button>
        </div>
        <a href="https://storage.googleapis.com/infringement_detector/Static/Template%20File%20-%20Data%20Import.xlsx" target="_blank" class="sample-file-link">Sample File</a>
    </div>
</div>

<!-- Step 2: File Parsing -->
<div *ngIf="currentStep === 2" class="parsing-section">
    <div class="upload-box">
        <div class="back-button-container">
            <i class="material-icons" (click)="onBack()">arrow_back</i>
        </div>
        <div class="file-information-container">

            <!-- <div class="file-details">
                <p><strong>File:</strong> {{ fileName }} ({{ fileSize }})</p>
            </div>

            <div class="progress-bar-container">
                <div class="progress-bar" [style.width]="progress + '%'"></div>
            </div> -->
            <div class="file-details">
                <img *ngIf="isExcelFileIcon==true" src="assets/images/excel-icon.png" alt="Excel Icon" class="file-icon" />
                <div class="file-text">
                    <p class="file-name">{{ fileName }}</p>
                    <p class="file-size">{{ fileSize }}</p>
                </div>
            </div>

            <!-- Progress Bar Section -->
            <div class="progress-container">
                <div class="progress-bar" [ngStyle]="{ 'width': progress + '%', 'background-color': isError ? 'red' : 'green' }">
                </div>
                <span class="progress-percent">{{ progress }}%</span>

                <!-- Checkmark or Error Tooltip -->
                <i *ngIf="progress === 100 && !isError" class="material-icons checkmark-icon">check_circle</i>

                <i *ngIf="isError && progress === 100" class="material-icons error-icon" matTooltip="{{ step2ErrorText }}">
                  error_outline
                </i>
            </div>
        </div>

        <button class="upload-button extract-button" mat-button color="primary" (click)="onExtract()" [class.disabled]="progress !== 100 || step2Error">Extract</button>

        <!-- Show error message -->
        <div *ngIf="step2Error" class="error-message">
            <p>{{ step2ErrorText }}</p>
        </div>
    </div>
</div>

<!-- Step 3: API Call Progress -->
<div *ngIf="currentStep === 3" class="api-call-section">
    <div class="upload-box">

        <p *ngIf="!isError">Extracting Data, Please wait...<br> While we work on our magic.</p>
        <p *ngIf="isError" class="error-message" [innerHTML]="step2ErrorText">
            <!-- <p>{{ step2ErrorText }}</p> -->
        </p>
        <div class="progress-bar-container">
            <div class="progress-bar step-3-progress" [style.width]="progress + '%'"></div>
        </div>

        <!-- Success Checkmark -->
        <div *ngIf="extractionComplete" class="completion-checkmark">
            <i class="material-icons">check_circle</i>
            <p>Completed!</p>
        </div>


        <!-- Error message -->
    </div>
</div>

<!-- Step 4: Display extracted patents -->
<!-- Step 4: Display extracted patents -->
<div *ngIf="currentStep === 4" class="table-section">
    <!-- <h1 class="first-header">Extracted Patent Sections</h1> -->
    <table class="patent-table">
        <thead>
            <tr>
                <th>S. No.</th>
                <th>Publication Number</th>
                <th>Title</th>
                <th>Current Assignee</th>
                <th>Priority Date</th>
                <th>Expiration Date</th>
                <th>Claims</th>
                <th>Technology</th>
                <th>Number of Claims (Independent)</th>
                <th>Number of Claims (Dependent)</th>
                <th>Status</th>
                <th>Remaining Life</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let section of allSectionsJson; let i = index">
                <td>{{ i + 1 }}</td>
                <td class="publication-number">
                    <a href="https://patents.google.com/patent/{{ section['Publication Number'] }}" target="_blank">
                        {{ section['Publication Number'] }}
                    </a>
                </td>
                <td>{{ section['Title'] }}</td>
                <td>{{ section['Current Assignee'] }}</td>
                <td>{{ section['Priority Date'] }}</td>
                <td>{{ section['Expiration Date'] }}</td>
                <td class="claims-cell" (click)="openClaimsDialog(section['Claims'])">
                    {{ section['Claims'][0].slice(0, 15) }}...
                </td>
                <td>{{ section['Technology'] }}</td>
                <td>{{ section['Independent Claims'] }}</td>
                <td>{{ section['Dependent Claims'] }}</td>
                <td>{{ section['Patent Status'] }}</td>
                <td>{{ getRemainingLife(section['Expiration Date']) }}</td>
            </tr>
        </tbody>
    </table>
</div>