import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ScoreLegendComponent } from "../common/score-legend/score-legend.component";
import { ValidityScoreComponent } from "../common/validity-score/validity-score.component";
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import { DataService } from '../services/data.service';
import { PopoverClose, PopoverComponent, PopoverModule, PopoverTemplate } from '@ngx-popovers/popover';

declare var html2pdf: any;

@Component({
  selector: 'app-sniff-report',
  standalone: true,
  imports: [CommonModule, ScoreLegendComponent, ValidityScoreComponent, PopoverClose, PopoverComponent, PopoverTemplate, PopoverModule],
  templateUrl: './sniff-report.component.html',
  styleUrl: './sniff-report.component.css'
})
export class SniffReportComponent {

  
  @Input () inputData:any=[];
  @Input () validitySkip:boolean=false;
  @Input () infringementData:any=[];
  @Input () validityData:any=[];
  @Input () industryData:any=[];

  exportUI:boolean=false;
  exportingState=false;
  
  constructor(private dataService:DataService) { 
    this.inputData=this.dataService.inputData;
    this.validityData=this.dataService.pasData;
    this.industryData=this.dataService.industryData;
    this.infringementData=this.dataService.infringementData;
    this.validitySkip=this.dataService.skipPas;
    console.log('skip pas:', this.validitySkip, this.dataService.skipPas)
  }
  currentTab="industry";

  selectedPatentIndex:any=null;
  infringementArray:any[]=[];
  claimChartData:any[]=[];

  ngOnInit(){
    this.validitySkip=this.dataService.skipPas;
    this.inputData=this.dataService.inputData;
    this.validityData=this.dataService.pasData;
    this.industryData=this.dataService.industryData;
    this.infringementData=this.dataService.infringementData;
    console.log('inputdata', this.inputData)
    console.log('infringement data',this.infringementData)
    console.log('industry data', this.industryData)
    console.log(this.validityData.referenceTableMap)
    this.infringementArray=this.infringementData.patentDetails;
    this.claimChartData=this.infringementData.claimChartData;


  }

  getFilteredClaimChartData(patentNumber:string){
    return this.claimChartData.filter((item:any)=>item.patent_number==patentNumber)
  }
  getFilteredIndustryData(patentNumber:string){
    return this.industryData.filter((item:any)=>item.patentNumber==patentNumber)
  }

  getSummaryData(patentNumber:any){
    return this.validityData.summaryData.filter((item:any)=>item.patentNumber==patentNumber)
  }

  getValidityScoreCount(){
    if(this.validitySkip)
      return 0;
    return this.validityData.summaryData.filter((x:any)=>x.score>7).length
  }

  getValidityScore(patentNumber:any){
    return this.validityData.summaryData.filter((item:any)=>item.patentNumber==patentNumber)[0].score
  }
  referenceTableOpenMapObj:any={};
  toggleReferenceTable(patentNumber:any, referenceNumber:any){
    if(!this.referenceTableOpenMapObj[patentNumber]){
      this.referenceTableOpenMapObj[patentNumber]={};
      this.referenceTableOpenMapObj[patentNumber][referenceNumber]=false;
    }
    else{
      if(!this.referenceTableOpenMapObj[patentNumber][referenceNumber]){
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=true;
      }
      else{
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=false;
      }
    }
  }

  referenceTableOpenMap(patentNumber:any, referenceNumber:any, index:any){
    // map based on patentNumber and further referenceNumber. If the reference Object does not exist, create a new object with the referenceNumber as key and value as false
    if(!this.referenceTableOpenMapObj[patentNumber]){
      if(index==0&&this.referenceTableOpenMapObj[patentNumber]===undefined){
        this.referenceTableOpenMapObj[patentNumber]={};
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=true;
      }
      else
      this.referenceTableOpenMapObj[patentNumber]={};
    }
    else{
      if(!this.referenceTableOpenMapObj[patentNumber][referenceNumber]){
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=true;
      }
      else{
        this.referenceTableOpenMapObj[patentNumber][referenceNumber]=false;
      }
    }
    return this.referenceTableOpenMapObj[patentNumber][referenceNumber];
  }

  getScoreValue(){
    if(this.selectedPatentIndex!=null){
      if(this.currentTab=='pas')
        return this.getValidityScore(this.inputData[this.selectedPatentIndex].patentNumber)

      return this.infringementData['patentInfringementScoreData'][this.inputData[this.selectedPatentIndex].patentNumber]
    }
    return 0;
  }

  setSelectedPatentIndex(patentNumber:string){
    console.log(patentNumber)
    let index=this.inputData.findIndex((item:any)=>item.patentNumber==patentNumber);
    this.selectedPatentIndex=index;
    console.log(index, this.inputData[index])
  }

  exportPDF(){
    this.exportingState=true;
    this.exportUI=true;
    setTimeout(() => {
    var element:any = document.getElementById('exportable-ui');
    if(this.selectedPatentIndex!=null){
      element=document.getElementById('major-report');
      console.log(element)
    }
    let opt={
      filename:'sniff-report.pdf',
      width: 2500,
      height:100,

    }
      
    html2canvas(element).then(canvas => {
      //$("#previewBeforeDownload").html(canvas);
      var imgData = canvas.toDataURL("image/jpeg",1);
      var pdf = new jsPDF("p", "px", "a2");
      // var pageWidth = pdf.internal.pageSize.getWidth();
      var pageWidth=pdf.internal.pageSize.getWidth();
      var pageHeight = pdf.internal.pageSize.getHeight();
      var imageWidth = canvas.width;
      var imageHeight = canvas.height;

      var ratio = imageWidth/imageHeight >= pageWidth/pageHeight ? pageWidth/imageWidth : pageHeight/imageHeight;
      //pdf = new jsPDF(this.state.orientation, undefined, format);
      pdf.addImage(imgData, 'JPEG', 0, 0, imageWidth * ratio, imageHeight * ratio);
      pdf.save("sniff-report.pdf");
  });

      setTimeout(() => {
        
        this.exportingState=false;
        this.exportUI=false;
      }, 100);
      // var printWindow:any = window.open('', '', 'height=2000px,width=2000px');
      //       printWindow.document.write('<html><head><title>DIV Contents</title>');
      //       // add contents of sniff-report.css from assets/css/sniff-report.css
      //       printWindow.document.write('<link rel="stylesheet" href="assets/css/sniff-report.css" type="text/css" />');            
      //       printWindow.document.write('</head><body >');
      //       printWindow.document.write(element?.innerHTML);
      //       printWindow.document.write('</body></html>');
      //       printWindow.document.close();
      //       printWindow.print();
    }, 10);
    }

    calculateInfringedCompanies(){
        
    }

    calculateRevenue(){
      
    }

    changeIndex(index:number){
      if(this.selectedPatentIndex+index>=0 && this.selectedPatentIndex+index<this.inputData.length){
        this.selectedPatentIndex+=index;
      }
    }

    getRevenueDetails(){
      let revenueArray:any=[];
      this.industryData.forEach((element:any) => {
        let dataArray=element.companies;
      for(let data of dataArray){
        let companyData={
          name:data.name,
          products:[]
        }
        for(let product of data.products){
          revenueArray.push(product.revenue.response)
        }
      }
    });

      console.log(revenueArray)
      let revenueString=this.sumCurrencies(revenueArray);
      console.log(revenueString)
      return revenueString;


    }

     sumCurrencies(values:any) {
        const denominations:any = {
            billion: 1e9,
            million: 1e6
        };
    
        let total = 0;
    
        for (let value of values) {
            const match = value.match(/\$?([\d.,]+ (million|billion|trillion)?)\s*(-\s*(\$?\s*[\d.,]+))?\s*(million|billion|trillion)?/i);
            if (!match) continue;
            console.log(match)
            const baseValue = parseFloat(match[1]);
            match[4] = match[4] ? match[4].replace('$', '') : null;
            const rangeEnd = match[4] ? parseFloat(match[4]) : null;
            let unit_candidate=match[5];
            if(!unit_candidate){
                unit_candidate=match[2];
            }
            const unit = unit_candidate ? unit_candidate.toLowerCase() : "million";
    
            if (rangeEnd && !value.includes('- $')) {
                total += (baseValue * denominations[unit] + rangeEnd * denominations[unit]) / 2;
            } else {
                const finalValue = rangeEnd
                    ? (baseValue * denominations[unit] + rangeEnd * denominations[unit]) / 2
                    : baseValue * denominations[unit];
    
                total += finalValue;
            }
        }
    
        if (total >= 1e9) {
            const totalInBillions = total / denominations.billion;
            const truncated = Math.floor(totalInBillions * 10) / 10;
            const hasExtra = (totalInBillions * 100) % 10 > 0;
            return hasExtra
                ? `$${truncated.toFixed(1)}+ B`
                : `$${truncated.toFixed(1)} B`;
        } else {
            const totalInMillions = total / denominations.million;
            const truncated = Math.floor(totalInMillions * 10) / 10;
            const hasExtra = (totalInMillions * 100) % 10 > 0;
            return hasExtra
                ? `$${truncated.toFixed(1)}+ M`
                : `$${truncated.toFixed(1)} M`;
        }
    
        return '$0';
    }
    getLinkOrAiEstimate(str:any){
        if(str.includes('http')){
          return `<a href=${str}>${str}</a>`
        }
        else if(str=='Unknown')
        {
          return "Unknown";
        }
        else{
          return `<h7>Ai Estimate</h7> <p>${str.split('(')[1]}</p>`
        }
      }
    

}