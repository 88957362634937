// prompt-management.component.ts
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { HttpService } from '../services/http.service';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-prompt-management',
  standalone: true,
  imports: [CommonModule, FormsModule, MatIconModule],
  templateUrl: './prompt-management.component.html',
  styleUrls: ['./prompt-management.component.css']
})
export class PromptManagementComponent implements OnInit {
  promptGroups: any[] = [];

  constructor(private http:HttpService, private dataService: DataService, private router:Router) {}

  ngOnInit() {
    this.fetchPromptGroups();
    let username=this.dataService.username;
    
  }

  fetchPromptGroups() {
    let sampleData:any = [
    ];
    this.http.get('/admin/prompts/list').then((response) => {
      console.log('Prompt data:', response);
      sampleData=response.prompts;
      this.groupPrompts(sampleData);
    })
    .catch((error) => {
      console.error('Error fetching prompts:', error);
    });
  }

  groupPrompts(promptGroups: any[]) {
    console.log(promptGroups)
    const groups: any = {};
    Object.keys(promptGroups).forEach((promptKey:any) => {
      if (!groups[promptKey]) {
        groups[promptKey] = { module_name: promptKey, expanded: false, prompts: [] };
      }
      groups[promptKey].prompts=promptGroups[promptKey].map((prompt:any)=>{return {...prompt, editing: false}});
    });
    console.log('prompt groups:', groups);
    this.promptGroups = Object.values(groups);
  }

  selectedGroup: any = null;

  toggleGroup(group: any) {
    // If the group is already open, close it and reset the selected group
    if (group.expanded) {
      group.expanded = false;
      this.selectedGroup = null; // Clear selected group
    } else {
      // Close other groups if needed
      this.promptGroups.forEach((g) => (g.expanded = false));
      group.expanded = true; // Open the clicked group
      this.selectedGroup = group.prompts; // Set selected group
    }
  }

  copyPrompt(prompt: any) {
    navigator.clipboard.writeText(prompt.promptValue);
    alert('Prompt copied to clipboard!');
  }

  editPrompt(prompt: any) {
    prompt.editing = true;
  }

  savePrompt(prompt: any) {
    console.log('Saving prompt:', prompt);
      /*
      {
    "collectionName": "prompt_library_prior_art_search",
    "promptId": "PAS-101",
    "promptValue": "Provide prior art for patent ID {patent_id}.",
    "createdBy": "admin_user",
    "updatedBy": "admin_user"
}
    url : /api/prompts/update
    method post
      */

    let collectionName='';
    Object.keys(this.promptGroups).forEach((promptKey:any) => {
      if(this.promptGroups[promptKey].prompts.includes(prompt)){
        collectionName=this.promptGroups[promptKey].module_name
      }
    })

     let reqBody={
      ...prompt,
      collectionName:collectionName,
        createdBy:this.dataService.username,
        updatedBy:this.dataService.username
     }

     delete reqBody.editing;
     delete reqBody.promptName;

    this.http.post('/api/prompts/update', reqBody).then((response) => {

      // Update lastUpdatedTime with the current time
      const currentDateTime = new Date();
      const formattedDateTime = this.formatDateTime(currentDateTime);
      this.selectedGroup.forEach((p: any) => {
        p.lastUpdatedTime = formattedDateTime; // Update all prompts in the selected group
      });
      console.log('Prompt updated:', response);
      prompt.editing = false;
    })
    .catch((error) => {
      console.error('Error updating prompt:', error);
    });
  }

  // Helper to format the date/time
  formatDateTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear();
    return `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`;
  }

  cancelEdit(prompt: any) {
    prompt.editing = false;
  }

  getHeadingFormatted(headingName:string){
    return headingName.split('_').map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  }

  getPromptNameFormatted(promptName:string){
    return promptName.split('_').map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ').replace(' ', ' ');
  }

  goBack(){
    this.router.navigate(['/']);
  }
}
