import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  inputData:any=[]
  industryData:any=[]
  infringementData:any={}
  pasData:any={}
  skipPas=false
  tempData:any=[]
  mainUploadData=[]
  filterDataBasedOnInfringemnetSelection(){
    let tempIndustryData=[]
    let tempPasData=[]
    console.log('inputData', this.inputData)
    console.log('infringementData', this.infringementData)
    console.log('industryData', this.industryData)
    console.log('pasData', this.pasData)
    this.infringementData.patentDetails.forEach((element:any) => {
      this.inputData.forEach((inputElement:any) => {
        if(element["Patent Number"]==inputElement["patentNumber"]){
          tempPasData.push(inputElement)
        }
      });
      this.industryData.forEach((industryElement:any) => {
        if(element["Company"]==industryElement["Company"]){
          tempIndustryData.push(industryElement)
        }
      });
    });
  }



  updateDataSameStructure(data:any, source=null, updateMainData=true){
    if(source=="pas"){
      this.pasData=data
    }
    if(source=="industry"){
      this.industryData=data
    }
    if(source=="infringement"){
      this.infringementData=data
      this.filterDataBasedOnInfringemnetSelection()
    }
    if(updateMainData){
      this.inputData=data
    }
  }
  // create an observable that can be subscribed to and updated using a function
  routeObservable:any=new EventEmitter()
  updateRouteObservable(data:any){
    this.routeObservable.emit(data)
  }
  onSkipPas(){
    this.skipPas=true
    this.routeObservable.emit('industry-analysis')
  }
  onClickNext(){
    this.routeObservable.emit('pas')
  }
  
  setMainUploadData(data:any){
    
    this.mainUploadData=data
    
    data.forEach((element:any) => {
      console.log('got data', data)
      console.log(this.inputData, this.tempData)
      this.inputData.push(
        {
          /*{ section['Title'] }}</td>
                <td>{{ section['Current Assignee'] }}</td>
                <td>{{ section['Priority Date'] }}</td>
                <td>{{ section['Expiration Date'] }}</td>
                <td class="claims-cell" (click)="openClaimsDialog(section['Claims'])">
                    {{ section['Claims'][0].slice(0, 15) }}...
                </td>
                <td>{{ section['Technology'] }}</td>
                <td>{{ section['Independent Claims'] }}</td>
                <td>{{ section['Dependent Claims'] }}</td>
                <td>{{ section['Patent Status'] }}*/
          patentNumber:element["Publication Number"],
          currentAssignee:element["Current Assignee"],
          priorityDate:element["Priority Date"],
          expirationDate:element["Expiration Date"],
          claims:element["Claims"],
          technology:element["Technology"],
          independentClaims:element["Independent Claims"],
          dependentClaims:element["Dependent Claims"],
          patentStatus:element["Patent Status"],
          


        }
      )
    });
  }
  onClickCancel(){
    this.routeObservable.emit('upload')
  }

  onClickGenerateReport(){
    this.routeObservable.emit('report')
  }

  usernameObservable:any=new EventEmitter()
  updateUsernameObservable(data:any){
    this.usernameObservable.emit(data)
  }
  getUsernameObservable(){
    let username=localStorage.getItem('username')
    if(username){
      this.usernameObservable.emit(username)
    return this.usernameObservable
  }
}

isUserAdmin(){
  // check in local storage if user is admi if the username is admin
  let username=localStorage.getItem('username')
  if(username=="admin"){
    return true
  }
  return false
}

get username(){
  return localStorage.getItem('username')
}

logOutObservable:any=new EventEmitter()

updateLogOutObservable(data:any){
  this.logOutObservable.emit(data)
}

}
