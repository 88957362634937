import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ETAInterceptor } from './interceptors/eta.interceptor';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/http.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([ETAInterceptor])),
    provideHttpClient(withInterceptors([AuthInterceptor])),
    provideAnimationsAsync(),
    importProvidersFrom(SatPopoverModule)
  ]
};
