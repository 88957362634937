import * as i0 from '@angular/core';
import { Injectable, inject, PLATFORM_ID, INJECTOR, viewChild, TemplateRef, input, computed, Component, ChangeDetectionStrategy, Inject, InjectionToken, output, Directive, HostListener, booleanAttribute, model, signal, effect, ChangeDetectorRef, numberAttribute, ViewEncapsulation } from '@angular/core';
import { computePosition, autoUpdate, offset, flip, arrow } from '@floating-ui/dom';
const _c0 = ["*"];
function PortalComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
const _c1 = ["floating"];
function FloatingComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ngx-portal", 1)(1, "div", 2, 0);
    i0.ɵɵlistener("ngxClickOutside", function FloatingComponent_Conditional_0_Template_div_ngxClickOutside_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onClick($event));
    });
    i0.ɵɵprojection(3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    let tmp_3_0;
    let tmp_4_0;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("bindTo", ctx_r1.bindTo());
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("left", (tmp_3_0 = ctx_r1.coords()) == null ? null : tmp_3_0.x, "px")("top", (tmp_4_0 = ctx_r1.coords()) == null ? null : tmp_4_0.y, "px");
  }
}
const _c2 = ["arrow"];
function Arrow_Conditional_0_ng_template_2_Template(rf, ctx) {}
function Arrow_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2, 0);
    i0.ɵɵtemplate(2, Arrow_Conditional_0_ng_template_2_Template, 0, 0, "ng-template", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const arrow_r1 = i0.ɵɵreference(1);
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵstyleMap(ctx_r1.styles);
    i0.ɵɵclassProp("hidden", !arrow_r1);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngComponentOutlet", ctx_r1.arrowComponent);
  }
}
export * from '@floating-ui/dom';
import * as i1 from '@angular/common';
import { isPlatformServer, isPlatformBrowser, DOCUMENT, CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, shareReplay, filter, map, of } from 'rxjs';
const Keys = obj => {
  return Object.keys(obj);
};
const isContainElement = (current, target) => {
  if (current instanceof Node && target instanceof Node) {
    return current.contains(target);
  } else {
    return false;
  }
};
const awaitTime = (time = 0) => {
  return new Promise(r => setTimeout(r, time));
};
const isHTML = input => {
  return input instanceof HTMLElement;
};
const isString = input => {
  return typeof input === 'string';
};
class FloatingService {
  constructor() {
    this.computePosition = computePosition;
    this.autoUpdate = autoUpdate;
  }
  static {
    this.ɵfac = function FloatingService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FloatingService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FloatingService,
      factory: FloatingService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FloatingService, [{
    type: Injectable
  }], null, null);
})();
class PlatformService {
  constructor() {
    this.platformId = inject(PLATFORM_ID);
    this.isServer = () => isPlatformServer(this.platformId);
    this.isBrowser = () => isPlatformBrowser(this.platformId);
  }
  static {
    this.ɵfac = function PlatformService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PlatformService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PlatformService,
      factory: PlatformService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PlatformService, [{
    type: Injectable
  }], null, null);
})();
function isServer(injector = inject(INJECTOR)) {
  const platform = injector.get(PlatformService);
  return platform.isServer();
}
function isBrowser(injector = inject(INJECTOR)) {
  return !isServer(injector);
}
class PortalComponent {
  parentBySelector(selector) {
    const element = this.document.querySelector(selector);
    if (!element) {
      console.error(`cannot find HTMLElement with query selector: ${selector}`);
    }
    return element;
  }
  constructor(document, viewContainerRef, renderer) {
    this.document = document;
    this.viewContainerRef = viewContainerRef;
    this.renderer = renderer;
    this.isServer = isServer();
    this.portalContent = viewChild(TemplateRef);
    this.bindTo = input();
    this.parent = computed(() => {
      const bindTo = this.bindTo();
      if (isHTML(bindTo)) {
        return bindTo;
      }
      if (isString(bindTo)) {
        return this.parentBySelector(bindTo);
      }
      return this.document.body;
    });
  }
  ngOnInit() {
    this.bind();
  }
  ngOnChanges() {
    this.bind();
  }
  ngOnDestroy() {
    this.panelRef?.remove();
    this.view?.destroy();
  }
  bind() {
    if (this.isServer) {
      return;
    }
    const portalContent = this.portalContent();
    if (portalContent && this.parent()) {
      this.view = this.viewContainerRef.createEmbeddedView(portalContent);
      this.panelRef = this.view.rootNodes[0];
      if (this.panelRef) {
        this.renderer.appendChild(this.parent(), this.panelRef);
      } else {
        console.error(`cannot render component into ${this.parent}`);
      }
    }
  }
  static {
    this.ɵfac = function PortalComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PortalComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PortalComponent,
      selectors: [["ngx-portal"]],
      viewQuery: function PortalComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.portalContent, TemplateRef, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        bindTo: [1, "bindTo"]
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([PlatformService]), i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function PortalComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, PortalComponent_ng_template_0_Template, 1, 0, "ng-template");
        }
      },
      dependencies: [CommonModule],
      styles: ["[_nghost-%COMP%]{width:0;height:0;display:contents}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PortalComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-portal',
      standalone: true,
      imports: [CommonModule],
      providers: [PlatformService],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-template>\n  <ng-content />\n</ng-template>\n",
      styles: [":host{width:0;height:0;display:contents}\n"]
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.ViewContainerRef
  }, {
    type: i0.Renderer2
  }], null);
})();
class NgxFloatingConfig {
  constructor(config = {}) {
    this.placement = 'bottom';
    this.autoUpdate = true;
    this.middleware = [offset(4), flip()];
    Object.assign(this, config);
  }
}
const NGX_FLOATING_CONFIG = new InjectionToken('NGX_FLOATING_CONFIG', {
  providedIn: 'root',
  factory: () => new NgxFloatingConfig()
});
class ClickOutsideDirective {
  constructor(el) {
    this.el = el;
    this.ngxClickOutside = output();
    this.inside = output();
    this.outside = output();
  }
  onClick(event) {
    const target = event.target;
    if (target) {
      if (isContainElement(this.el.nativeElement, target)) {
        this.inside.emit(target);
        this.ngxClickOutside.emit({
          inside: true,
          outside: false,
          target
        });
      } else {
        this.outside.emit(target);
        this.ngxClickOutside.emit({
          inside: false,
          outside: true,
          target
        });
      }
    }
  }
  static {
    this.ɵfac = function ClickOutsideDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ClickOutsideDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ClickOutsideDirective,
      selectors: [["", "ngxClickOutside", ""]],
      hostBindings: function ClickOutsideDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function ClickOutsideDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event);
          }, false, i0.ɵɵresolveDocument);
        }
      },
      outputs: {
        ngxClickOutside: "ngxClickOutside",
        inside: "inside",
        outside: "outside"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClickOutsideDirective, [{
    type: Directive,
    args: [{
      selector: '[ngxClickOutside]',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    onClick: [{
      type: HostListener,
      args: ['document:click', ['$event']]
    }]
  });
})();
class FloatingComponent {
  ngAfterViewInit() {
    return this.bindToReference();
  }
  ngOnChanges() {
    return this.bindToReference();
  }
  ngDoCheck() {
    this.referenceConnected.set(this._reference()?.isConnected);
  }
  ngOnDestroy() {
    this.cleanup?.();
  }
  constructor() {
    this.config = inject(NGX_FLOATING_CONFIG);
    this.floatingService = inject(FloatingService);
    this.isServer = isServer();
    this.floatingRef = viewChild('floating');
    /**
     * TODO
     *  remove after v.18.
     *  Use the `reference` input instead
     * @deprecated
     */
    this.trigger = input();
    this.reference = input();
    /**
     * TODO: remove and use reference after v.18
     */
    this._reference = computed(() => this.reference() || this.trigger());
    this.placement = input(this.config.placement);
    this.strategy = input(this.config.strategy);
    this.autoUpdate = input(this.config.autoUpdate, {
      transform: booleanAttribute
    });
    this.arrow = model();
    this.middleware = input(this.config.middleware);
    this.bindTo = input(this.config.bindTo);
    this.clickedOutside = output();
    this.clickedInside = output();
    this.computePositionReturn = output();
    /* Is reference element exist in the DOM */
    this.referenceConnected = signal(false);
    this.arrowMiddleware = computed(() => this.getArrowMiddleware(this.arrow()));
    this._computePosition$ = new BehaviorSubject(undefined);
    this.computePosition$ = this._computePosition$.asObservable().pipe(shareReplay());
    this.coords = toSignal(this.computePosition$.pipe(filter(Boolean), map(({
      x,
      y
    }) => ({
      x,
      y
    }))));
    effect(() => {
      if (this.referenceConnected()) {
        this.bindToReference();
      }
    });
  }
  async bindToReference() {
    if (this.isServer) {
      return;
    }
    this.cleanup?.();
    const reference = this._reference();
    const floating = this.floatingRef()?.nativeElement;
    if (reference && floating) {
      if (this.autoUpdate()) {
        this.cleanup = this.floatingService.autoUpdate(reference, floating, async () => {
          await this.computePosition(reference, floating);
        });
      } else {
        this.cleanup = undefined;
        await this.computePosition(reference, floating);
      }
    }
    return;
  }
  async computePosition(reference, floating) {
    const computePositionReturn = await this.floatingService.computePosition(reference, floating, {
      placement: this.placement(),
      strategy: this.strategy(),
      middleware: [...this.middleware(), this.arrowMiddleware()]
    });
    this._computePosition$.next(computePositionReturn);
    this.computePositionReturn.emit(computePositionReturn);
  }
  onClick({
    target,
    inside,
    outside
  }) {
    if (inside) {
      this.onClickInside(target);
    }
    if (outside) {
      this.onClickOutside(target);
    }
  }
  /* Check if user clicked outside the floating element*/
  onClickOutside(target) {
    if (target instanceof Element) {
      /* Ignore the reference element */
      if (target !== this._reference()) {
        this.clickedOutside.emit(target);
      }
    }
  }
  /* Check if user clicked inside the floating element*/
  onClickInside(target) {
    if (target instanceof Element) {
      /* Ignore the reference element */
      if (target !== this._reference()) {
        this.clickedInside.emit(target);
      }
    }
  }
  /**
   * Arrow sets from <ngx-arrow/> component
   */
  setArrow(arrow) {
    this.arrow.set(arrow);
    return this.bindToReference();
  }
  getArrowMiddleware(arr) {
    const arrRef = arr?.arrowRef();
    if (arr && arrRef) {
      return arrow({
        element: arrRef.nativeElement,
        padding: arr.padding()
      });
    }
    return null;
  }
  static {
    this.ɵfac = function FloatingComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FloatingComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FloatingComponent,
      selectors: [["ngx-floating"]],
      viewQuery: function FloatingComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.floatingRef, _c1, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        trigger: [1, "trigger"],
        reference: [1, "reference"],
        placement: [1, "placement"],
        strategy: [1, "strategy"],
        autoUpdate: [1, "autoUpdate"],
        arrow: [1, "arrow"],
        middleware: [1, "middleware"],
        bindTo: [1, "bindTo"]
      },
      outputs: {
        arrow: "arrowChange",
        clickedOutside: "clickedOutside",
        clickedInside: "clickedInside",
        computePositionReturn: "computePositionReturn"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([FloatingService, PlatformService]), i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 1,
      consts: [["floating", ""], [3, "bindTo"], [1, "floating", 3, "ngxClickOutside"]],
      template: function FloatingComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, FloatingComponent_Conditional_0_Template, 4, 5, "ngx-portal", 1);
        }
        if (rf & 2) {
          i0.ɵɵconditional(!ctx.isServer && ctx.referenceConnected() ? 0 : -1);
        }
      },
      dependencies: [CommonModule, PortalComponent, ClickOutsideDirective],
      styles: ["[_nghost-%COMP%]{width:max-content;display:contents}.floating[_ngcontent-%COMP%]{width:max-content;position:absolute;top:0;left:0;z-index:999;will-change:left,top}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FloatingComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-floating',
      standalone: true,
      imports: [CommonModule, PortalComponent, ClickOutsideDirective],
      providers: [FloatingService, PlatformService],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "@if (!isServer && referenceConnected()) {\n  <ngx-portal [bindTo]=\"bindTo()\">\n    <div\n      #floating\n      class=\"floating\"\n      [style.left.px]=\"coords()?.x\"\n      [style.top.px]=\"coords()?.y\"\n      (ngxClickOutside)=\"onClick($event)\"\n    >\n      <ng-content />\n    </div>\n  </ngx-portal>\n}\n",
      styles: [":host{width:max-content;display:contents}.floating{width:max-content;position:absolute;top:0;left:0;z-index:999;will-change:left,top}\n"]
    }]
  }], () => [], null);
})();
class ArrowBase {
  static {
    this.ɵfac = function ArrowBase_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ArrowBase)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ArrowBase,
      selectors: [["ngx-arrow-base"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 0,
      consts: [[1, "arrow"]],
      template: function ArrowBase_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
        }
      },
      styles: [".arrow[_ngcontent-%COMP%]{width:12px;height:12px;transform:rotate(45deg);background:#000}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ArrowBase, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'ngx-arrow-base',
      template: `
    <div class="arrow"></div>
  `,
      styles: [".arrow{width:12px;height:12px;transform:rotate(45deg);background:#000}\n"]
    }]
  }], null, null);
})();
const NGX_ARROW_COMPONENT = new InjectionToken('NGX_ARROW_COMPONENT', {
  providedIn: 'root',
  factory: () => {
    return ArrowBase;
  }
});
const staticSides = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right'
};
class Arrow {
  constructor() {
    this.arrowComponent = inject(NGX_ARROW_COMPONENT);
    this.cdRef = inject(ChangeDetectorRef);
    this.arrowRef = viewChild('arrow');
    this.floating = model(inject(FloatingComponent, {
      optional: true
    }));
    this.padding = input(0, {
      transform: numberAttribute
    });
    this.styles = {};
  }
  async ngOnChanges() {
    await this.updateState();
  }
  async ngAfterViewInit() {
    await this.updateState();
  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
  async updateState() {
    const floating = this.floating();
    if (floating) {
      await floating.setArrow(this);
    }
    this.observe();
  }
  /*
  * Observe changes from computePosition of the floating.
  * Convert the ComputePosition data to the Arrow's styles
  */
  observe() {
    this.subscription?.unsubscribe();
    this.subscription = this.computeStyles$().subscribe(styles => {
      this.styles = styles;
      this.cdRef.markForCheck();
    });
  }
  computeStyles$() {
    return this.floating()?.computePosition$.pipe(filter(Boolean), map(data => {
      return this.computePosition(data);
    })) ?? of({});
  }
  getSide(placement) {
    const side = placement.split('-')[0];
    return staticSides[side];
  }
  /**
   * Set the floating element programmatically.
   * Need for setting the Floating if DI is not allowed.
   */
  setFloating(floating) {
    this.floating.set(floating);
    this.cdRef.detectChanges();
    return this.updateState();
  }
  /**
   * computePosition converts data from ComputePositionReturn
   * to Arrow's styles object Record<string, string>
   */
  computePosition({
    middlewareData,
    placement
  }) {
    const arrowElement = this.arrowRef()?.nativeElement;
    if (middlewareData.arrow && arrowElement) {
      const {
        x,
        y
      } = middlewareData.arrow;
      const staticSide = this.getSide(placement);
      const styles = {};
      if (x != null) {
        styles['left'] = `${x}px`;
      }
      if (y != null) {
        styles['top'] = `${y}px`;
      }
      if (staticSide) {
        styles[staticSide] = `${-arrowElement.offsetWidth / 2}px`;
      }
      return styles;
    }
    return {};
  }
  static {
    this.ɵfac = function Arrow_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || Arrow)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: Arrow,
      selectors: [["ngx-arrow"]],
      viewQuery: function Arrow_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.arrowRef, _c2, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        floating: [1, "floating"],
        padding: [1, "padding"]
      },
      outputs: {
        floating: "floatingChange"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [["arrow", ""], [1, "ngx-arrow", 3, "hidden", "style"], [1, "ngx-arrow"], [3, "ngComponentOutlet"]],
      template: function Arrow_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, Arrow_Conditional_0_Template, 3, 5, "div", 1);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.floating() ? 0 : -1);
        }
      },
      dependencies: [CommonModule, i1.NgComponentOutlet],
      styles: [".ngx-arrow{position:absolute;z-index:-1}.ngx-arrow.hidden{opacity:0}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Arrow, [{
    type: Component,
    args: [{
      selector: 'ngx-arrow',
      standalone: true,
      imports: [CommonModule],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "@if (floating()) {\n  <div\n    #arrow\n    class=\"ngx-arrow\"\n    [class.hidden]=\"!arrow\"\n    [style]=\"styles\"\n  >\n    <ng-template\n      [ngComponentOutlet]=\"arrowComponent\"\n    />\n  </div>\n}\n",
      styles: [".ngx-arrow{position:absolute;z-index:-1}.ngx-arrow.hidden{opacity:0}\n"]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Arrow, ArrowBase, ClickOutsideDirective, FloatingComponent, FloatingService, Keys, NGX_ARROW_COMPONENT, NGX_FLOATING_CONFIG, NgxFloatingConfig, PlatformService, PortalComponent, awaitTime, isBrowser, isContainElement, isHTML, isServer, isString };
