import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-claims-dialog',
  template: `
    <div class="dialog-header">
      <h2>{{ data.publicationNumber }}</h2>
      <span class="close-icon" (click)="onClose()">&#x2716;</span>
    </div>
    <div class="dialog-content">
      <h3>Claims</h3>
      <div class="claims-list">
        <div *ngFor="let claim of data.claims">{{ claim }}</div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  styleUrls: ['./claims-dialog.component.css']
})
export class ClaimsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ClaimsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
