import { Component, Input, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {ProgressBarLoaderComponent} from '../common/progress-bar-loader/progress-bar-loader.component';
import { FormsModule } from '@angular/forms';
import { HttpService } from '../services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { LinkInputPopupComponent } from '../common/popups/link-input-popup/link-input-popup.component';
import { FileUploadPopupComponent } from '../common/popups/file-upload-popup/file-upload-popup.component';
import { LinksDialogComponent } from '../links-dialog/links-dialog.component';
import { ValidityScoreComponent } from "../common/validity-score/validity-score.component";
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-infringement-analysis',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, FormsModule, HttpClientModule, ProgressBarLoaderComponent, ValidityScoreComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './infringement-analysis.component.html',
  styleUrls: ['./infringement-analysis.component.css']
})
export class InfringementAnalysisComponent implements OnInit {
  isLoading: boolean = false;
  @Input() patentData: any = [
]
  currentStage: number = 2;
  loadingText: string = 'Loading, please wait';
  titleText: string = 'Infringement Assessment';
  patentDetails: any[] = [];
  mock: boolean = false; // Set this to true for mock responses
  isEditingCompanies: boolean = false;
  isEditingProducts: boolean = false;
  @Input() updateData:any;
  @Input() updateRoute:any;

  apiResponse:any={};

  constructor(private http: HttpService, public dialog: MatDialog, public dataService:DataService) {

    this.patentData= this.dataService.inputData;
    this.updateData=this.dataService.updateDataSameStructure.bind(this.dataService);
    this.updateRoute=this.dataService.updateRouteObservable.bind(this.dataService);
  }
  
  ngOnInit(): void {
    this.patentDetails=this.patentData;
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        this.companySelectionList.push(`${company.name}-${patentInfo.patentNumber}`);
        this.productSelectionList.push(...company.products.map((product:any)=>product.name));
      });
    });
    // this.getTargetLinks();
  }

  companySelectionList:any=[];
  productSelectionList:any=[];
  claimChartData:any= []
  patentInfringementScoreData:any={}

  handleCompanyCheckboxChange(companyObject:any, patentNumber:any, eventTarget:any){
    const companyKey = `${companyObject.name}-${patentNumber}`;
    if(eventTarget.checked){
      this.companySelectionList.push(companyKey);
      this.productSelectionList.push(...companyObject.products.map((product:any)=>product.name));
      this.productSelectionList=[...new Set(this.productSelectionList)];
    }
    else{
      this.companySelectionList=this.companySelectionList.filter((company:any)=>company!==companyKey);
      this.productSelectionList=this.productSelectionList.filter((product:any)=>!companyObject.products.map((product:any)=>product.name).includes(product));
    }  
  }

  getCompanyCheckboxChecked(companyObject:any, patentNumber:any){
    const companyKey = `${companyObject.name}-${patentNumber}`;
    return this.companySelectionList.includes(companyKey);
  }

  handleProductCheckboxChange(productObject:any,productCompanyObject:any, patentNumber:any, eventTarget:any){
    let companyKey = `${productCompanyObject.name}-${patentNumber}`;
    if(eventTarget.checked){
      this.productSelectionList.push(productObject.name);
      if(!this.companySelectionList.includes(productCompanyObject.name)){
        this.companySelectionList.push(companyKey);
      }
    }
    else{
      this.productSelectionList=this.productSelectionList.filter((product:any)=>product!==productObject.name);
      // check if no product from the company is selected
      if(!this.productSelectionList.some((product:any)=>productCompanyObject.products.map((product:any)=>product.name).includes(product))){
        this.companySelectionList=this.companySelectionList.filter((company:any)=>company!==companyKey);
      }
    }
  }

  

  getProductCheckboxChecked(productObject:any,productCompanyObject:any, patentNumber:any){
    let companyKey = `${productCompanyObject.name}-${patentNumber}`;
    return this.productSelectionList.includes(productObject.name) && this.companySelectionList.includes(companyKey);
  }
    
  proceedToTargetLinks(){
    this.currentStage=1;
    // filter the patent details based on the selected companies and products
    this.patentDetails=this.patentData.map((patent:any)=>{
      return {
        ...patent,
        companies: patent.companies.filter((company:any)=>this.companySelectionList.includes(`${company.name}-${patent.patentNumber}`)).map((company:any)=>{
          return {
            ...company,
            products: company.products.filter((product:any)=>this.productSelectionList.includes(product.name))
          }
        })
      }
    });
    // filter to only those patents that have at least one company or product selected
    this.patentDetails=this.patentDetails.filter((patent:any)=>patent.companies.length>0);
    this.getTargetLinks();
  }

  proceedToClaimChartGeneration(){
    this.currentStage=3;
    // this.currentStage=4
    this.generateClaimChart();
  }

  proceedToSummary(){
    this.titleText="Summary";
    this.currentStage=5;
  }

  onFinalNext(){
    this.saveClaimCharts().then(()=>{

    this.updateData({patentDetails: this.patentDetails,patentInfringementScoreData:this.patentInfringementScoreData, claimChartData:this.claimChartData }, "infringement", false)
    this.updateRoute("post-upload")
    }).catch(error => {
      console.error('Error saving claim charts:', error);
    }
    )

  }

  saveClaimCharts(){
   return this.http.post('/infringement-assessment/save-claim-charts', {data: this.apiResponse}).then(response => {
      console.log(response)
    })
  }



  getTargetLinks(){
    /*
     Endpoint: /infringement-assesment/techinical-documentation
     Request: {"data": list of dictionaries ([{}, {}, {},………..])}
where each dictionary is {"targeted_company": str, "targeted_product": str}
Response:{
    "technical_documentation_links": [
        {
            "targeted_company": "Samsung Healthcare",
            "targeted_product": "Samsung Healthcare Computed Tomography Devices with Photon Counting Detector (PCD) Technology",
            "technical_documentation_links": [
                "https://www.surgicalroboticstechnology.com/news/neurologica-wins-fda-510k-clearance-for-photon-counting-computed-tomography-using-omnitom-elite/",
                "https://news.samsung.com/global/neurologica-announces-fda-510k-clearance-for-photon-counting-computed-tomography-using-omnitom-elite",
                "https://www.neurologica.com/publications/press-releases/samsung-ushers-in-a-new-era-of-diagnostic-solutions-at-rsna-2023",
                "https://www.samsunghealthcare.com/en/about_us/news_center/310",
                "https://www.neurologica.com/publications/press-releases/neurologicas-omnitom-elite-ct-scanner-now-available-0",
                "https://news.samsung.com/global/samsungs-push-to-leverage-artificial-intelligence-on-display-at-rsna-2021",
                "https://news.samsung.com/global/neurologica-corp-receives-fda-510k-clearance-for-enhanced-omnitom-elite-with-ultra-high-resolution-pcd-technology",
                "https://samsunghealthcare.com/en/about_us/news_center/185",
                "https://www.massdevice.com/fda-clears-neurologicas-omnitom-elite-with-photon-counting-detector-technology/",
                "https://samsunghealthcare.com/en/rsna2022"
            ]
        },
  }/**
   * 
   */
  if(this.mock){
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        company.products.forEach((product:any) => {
          product.technicalDocumentationLinks=[
            "https://www.surgicalroboticstechnology.com/news/neurologica-wins-fda-510k-clearance-for-photon-counting-computed-tomography-using-omnitom-elite/",
            "https://news.samsung.com/global/neurologica-announces-fda-510k-clearance-for-photon-counting-computed-tomography-using-omnitom-elite",
            "https://www.neurologica.com/publications/press-releases/samsung-ushers-in-a-new-era-of-diagnostic-solutions-at-rsna-2023",
            "https://www.samsunghealthcare.com/en/about_us/news_center/310",
            "https://www.neurologica.com/publications/press-releases/neurologicas-omnitom-elite-ct-scanner-now-available-0",
            "https://news.samsung.com/global/samsungs-push-to-leverage-artificial-intelligence-on-display-at-rsna-2021",
            "https://news.samsung.com/global/neurologica-corp-receives-fda-510k-clearance-for-enhanced-omnitom-elite-with-ultra-high-resolution-pcd-technology",
            "https://samsunghealthcare.com/en/about_us/news_center/185",
            "https://www.massdevice.com/fda-clears-neurologicas-omnitom-elite-with-photon-counting-detector-technology/",
            "https://samsunghealthcare.com/en/rsna2022"
          ]
        });
      });
    });
  }
  else {
    let request_body:any=[]
    this.loadingText = 'Fetching technical documentation links, please wait';
    this.isLoading = true;
    console.log(this.patentDetails)
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        company.products.forEach((product:any) => {
          request_body.push({
            targeted_company: company.name,
            targeted_product: product.name
          });
        });
      });
    });
    this.http.post('/infringement-assessment/technical-documentations', {data: request_body}).then(response => {
      let index=0;
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company:any) => {
          company.products.forEach((product:any) => {
            product.technicalDocumentationLinks=response.technical_documentation_links[index++].technical_documentation_links;
          });
        });
      });
      console.log('Technical documentation links:', this.patentDetails);
      this.isLoading = false;
      this.currentStage=3;
    }).catch(error => {
      this.isError=true;
      console.error('Error fetching technical documentation links:', error);
    });

  }
}

isError:boolean=false;

generateClaimChart(){
  /*
  Endpoint: /infringement-assesment/claim-charts
  Request: {"data": list of dictionaries ([{}, {}, {},………..])}
where each dictionary is {"patent_number": str, "targeted_product": str, "technical_documentation_links": list of links (list of strings), "prompt_template_source": str}

Note: The value of "prompt_template_source" should be "file"

  Response: 
    "claim_charts_data": {
        "claim_charts": [
            {
                "patent_number": "US8373135B2",
                "targeted_product": "Samsung Healthcare Computed Tomography Devices with Photon Counting Detector (PCD) Technology",
                "claim": "A counting detector, comprising: sensors to convert radiation quanta into electrical pulses; an evaluation unit, including a number of energy thresholds, to generate, for each of the sensors, a count value for each of the energy thresholds from the electrical pulses, each of the count values representing a number of radiation quanta with an energy above a respective one of the energy thresholds, wherein one of the energy thresholds is arranged directly above a characteristic energy of radiation quanta causing double counting in order to correct double counting; and a correction unit to calculate a corrected count value from the count values of the energy thresholds, the corrected count value including reduced double counting for at least one of the energy thresholds.",
                "data": [
                    {
                        "claim_element": "- A counting detector  ",
                        "analyst_comment": "The claim element 'A counting detector' is explicitly supported by multiple references to the Photon Counting Detector (PCD) technology utilized in the OmniTom Elite CT scanner. These references describe the functionality and capabilities of the PCD, emphasizing its role in capturing CT data and generating spectral images, which aligns directly with the concept of a counting detector.",
                        "references": [
                            {
                                "reference": "Counting DetectorPhoton Counting Detector (PCD)Samsung NeuroLogica\n\n\nProducts > More ProductsPress Resources > Press Release \n\n\nRelated Articles\n\n\nSamsung’s Push To Leverage Artificial Intelligence on Display at RSNA 2021",
                                "source": "https://news.samsung.com/global/neurologica-announces-fda-510k-clearance-for-photon-counting-computed-tomography-using-omnitom-elite"
                            },
                            {
                                "reference": "NeuroLogica Corp, a subsidiary of Samsung Electronics Co. Ltd., announced today its state-of-the-art OmniTom Elite has received 510(k) clearance for the addition of Photon Counting Detector (PCD) technology.",
                                "source": "https://www.neurologica.com/publications/press-releases/neurologicas-omnitom-elite-ct-scanner-now-available-0"
                            },
                            {
                                "reference": "NeuroLogica delivers the first FDA 510(k) cleared, single-source photon counting computed tomography (CT) scanner with single detector.\n\nOmniTom Elite with PCD can generate spectral CT imagesat multiple energy levels.\n\nPhoton counting is a next-generation CT technology that sorts the different energies of X-rays after they have passed through the scan field.\n\nA single X-ray source paired with PCD generates multiple sets of CT data acquired at the same time with configurable energy thresholds without any cross talk between images.\n\nPCD provides the ability to capture CT data in multiple energy bands leading to potentially more accurate visualization and segmentation of bone, blood clots, plaque, hemorrhage, and intracranial tumors.\n\nThere is also potential with PCD to lower the dose requirements, and fundamentally change the use of injected contrast.",
                                "source": "https://www.neurologica.com/publications/press-releases/neurologicas-omnitom-elite-ct-scanner-now-available-0"
                            },
                            {
                                "reference": "The clinical launch of the OmniTom Elite with PCD, the first US FDA 510(k) cleared single-source photon counting computed tomography scanner with a single detector on a mobile system, will be featured at this year’s meeting.\n\nPhoton counting is a next-generation CT technology that sorts the different energies of X-rays after they have passed through the scan field, capturing the X-ray photons directly at the detector and converting the energy to electrical signals.",
                                "source": "https://www.neurologica.com/publications/press-releases/samsung-ushers-in-a-new-era-of-diagnostic-solutions-at-rsna-2023"
                            },
                            {
                                "reference": "The world of medical imaging is no longer just black & white.\n\nNeuroLogica Corp, a subsidiary of Samsung Electronics, announced today its state-of-the-art OmniTom Elite has received 510(k) clearance for the addition of Photon Counting Detector (PCD) technology.\n\nNeuroLogica delivers the first FDA 510(k) cleared, single-source photon counting computed tomography (CT) scanner with single detector.\n\nOmniTom Elite with PCD can generate spectral CT images at multiple energy levels.\n\nPhoton counting is a next-generation CT technology that sorts the different energies of X-rays after they have passed through the scan field.\n\nA single X-ray source paired with PCD generates multiple sets of CT data acquired at the same time with configurable energy thresholds without any cross talk between images.\n\nPCD provides the ability to capture CT data in multiple energy bands leading to potentially more accurate visualization and segmentation of bone, blood clots, plaque, hemorrhage and intracranial tumors.\n\nThere is also potential with PCD to lower the dose requirements, and fundamentally change the use of injected contrast.",
                                "source": "https://news.samsung.com/global/neurologica-announces-fda-510k-clearance-for-photon-counting-computed-tomography-using-omnitom-elite"
                            },
                            {
                                "reference": "NeuroLogica Corp, a subsidiary of Samsung Electronics, announced its latest configuration of the mobile computed tomography (CT) OmniTom® Elite with Photon Counting Detector (PCD) technology has received U.S. Food and Drug Administration (FDA) 510(k) clearance.\n\nThis advancement builds upon the existing PCD capabilities with groundbreaking enhancements, further solidifying OmniTom® Elite’s position at the forefront of mobile CT imaging technology.\n\nThe newly cleared features optimized for PCD include several significant innovations:\n \n\nUltra-High-Resolution Mode: This development promises unprecedented clarity and detail in CT imaging.\n\nThe addition of an ultra-high-resolution mode now offers an impressive 0.141 mm resolution.",
                                "source": "https://news.samsung.com/global/neurologica-corp-receives-fda-510k-clearance-for-enhanced-omnitom-elite-with-ultra-high-resolution-pcd-technology"
                            }
                        ]
                    },
                ],
                "score": 9
            },
            
        ],
        "patent_infringement_score": {"US8373135B2": 9}
    }
}

*/
 if(this.mock){
  this.patentDetails.forEach(patentInfo => {
    patentInfo.companies.forEach((company:any) => {
      company.products.forEach((product:any) => {
        product.claimChart={
          "patent_number": "US8373135B2",
          "targeted_product": "Samsung Healthcare Computed Tomography Devices with Photon Counting Detector (PCD) Technology",
          "claim": "A counting detector, comprising: sensors to convert radiation quanta into electrical pulses; an evaluation unit, including a number of energy thresholds, to generate, for each of the sensors, a count value for each of the energy thresholds from the electrical pulses, each of the count values representing a number of radiation quanta with an energy above a respective one of the energy thresholds, wherein one of the energy thresholds is arranged directly above a characteristic energy of radiation quanta causing double counting in order to correct double counting; and a correction unit to calculate a corrected count value from the count values of the energy thresholds, the corrected count value including reduced double counting for at least one of the energy thresholds.",
          "data": [
              {
                  "claim_element": "- A counting detector  ",
                  "analyst_comment": "The claim element 'A counting detector' is explicitly supported by multiple references to the Photon Counting Detector (PCD) technology utilized in the OmniTom Elite CT scanner. These references describe the functionality and capabilities of the PCD, emphasizing its role in capturing CT data and generating spectral images, which aligns directly with the concept of a counting detector.",
                  "references": [
                      {
                          "reference": "Counting DetectorPhoton Counting Detector (PCD)Samsung NeuroLogica\n\n\nProducts > More ProductsPress Resources > Press Release \n\n\nRelated Articles\n\n\nSamsung’s Push To Leverage Artificial Intelligence on Display at RSNA 2021",
                          "source": "https://news.samsung.com/global/neurologica-announces-fda-510k-clearance-for-photon-counting-computed-tomography-using-omnitom-elite"
                      },
                      {
                          "reference": "NeuroLogica Corp, a subsidiary of Samsung Electronics Co. Ltd., announced today its state-of-the-art OmniTom Elite has received 510(k) clearance for the addition of Photon Counting Detector (PCD) technology.",
                          "source": "https://www.neurologica.com/publications/press-releases/ne"
                      },
                    ]
                },
            ],
            "score": 9
        }
      });
    });
    patentInfo.patentInfringementScore={"US8373135B2": 9}
  });
}
else {
  const requestBody:any=[]
  this.patentDetails.forEach(patentInfo => {
    patentInfo.companies.forEach((company:any) => {
      company.products.forEach((product:any) => {
        requestBody.push({
          patent_number: patentInfo.patentNumber,
          targeted_product: product.name,
          technical_documentation_links: product.technicalDocumentationLinks,
          prompt_template_source: 'file'
        });
      });
    });
  });
  this.loadingText = 'Generating claim charts, please wait';
  this.isLoading = true;
  this.http.post('/infringement-assessment/claim-charts', {data: requestBody}).then(response => {
    this.apiResponse=response;
   this.claimChartData=response.claim_charts_data.claim_charts;
   this.patentInfringementScoreData=response.claim_charts_data.patent_infringement_score;
    this.isLoading = false;
    this.currentStage=4;
  }).catch(error => {
    console.error('Error generating claim charts:', error);
    this.isError=true
  }
  );
}
}

regenerateClaimChart(patentNumber:any, product:any){
  let foundProduct:any=undefined;
  this.patentDetails.forEach(patentInfo => {
    patentInfo.companies.forEach((company:any) => {
      company.products.forEach((product:any) => {
        if(product.name==product.name){
          foundProduct=product;
        }
      });
    });
  });
  
  const requestBody={
    patent_number: patentNumber,
    targeted_product: foundProduct.name,
    technical_documentation_links: foundProduct.technicalDocumentationLinks,
    prompt_template_source: 'file'
  };
  this.loadingText = 'Regenerating claim chart, please wait';
  this.isLoading = true;

  this.http.post('/infringement-assessment/claim-charts', {data: [requestBody]}).then(response => {
    this.claimChartData=this.claimChartData.map((claimChart:any)=>{
      if(claimChart.patent_number==patentNumber && claimChart.targeted_product==product.name){
        return response.claim_charts_data.claim_charts[0];
      }
      return claimChart;
    });
    this.isLoading = false;

    this.patentInfringementScoreData=response.claim_charts_data.patent_infringement_score;
  }).catch(error => {
    // this.isLoading = false;
    this.isError=true;
    console.error('Error regenerating claim chart:', error);
  });
}

  hanldeInputLinkUpdate(company:any, product:any)
  {
    const dialogRef = this.dialog.open(LinkInputPopupComponent, {
      width: '300px',
      data: { inputValue: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        console.log('Value received from popup:', result);
        if(!product.technicalDocumentationLinks){
          product.technicalDocumentationLinks=[];
        }
        product.technicalDocumentationLinks.push(result);
      }
    });
  }

  handleFileLinkUpdate(company:any, patent:any){
    const dialogRef = this.dialog.open(FileUploadPopupComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        console.log('File received from popup:', result);
        this.uploadFileLinkUpdate(result, company, patent);
      }
    });
  
  }

  openLinksDialog(product:any)
  {
    const dialogRef = this.dialog.open(LinksDialogComponent, {
      width: '600px',
      data: { links: product.technicalDocumentationLinks }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        console.log('Value received from popup:', result);
        product.technicalDocumentationLinks=result;
      }
    });
  }

  minStep=2;

  goBack(){
    if(this.currentStage>this.minStep)
      this.currentStage--;
    else{
      window.history.back();
    }
  }
  
  onClickCancel(){
    this.dataService.onClickCancel.bind(this.dataService)();
  }

  uploadFileLinkUpdate(file:any, company:any, product:any){
    console.log(file)
    let formData=new FormData();
    formData.append('file', file);
    this.loadingText = 'Uploading file, please wait';
    this.isLoading = true;
    this.http.postFormData('//infringement-assessment/upload-user-technical-documentation', formData).then(response => {
      console.log(response)
      if(!product.technicalDocumentationLinks){
        product.technicalDocumentationLinks=[];
      }
      product.technicalDocumentationLinks.push(response.user_technical_documentation_link);
      this.isLoading = false;
    }).catch(error => {
      this.isLoading = false;
      console.error('Error uploading file:', error);
    });
  }

  startTargetScouting(): void {
    this.isLoading = true;
    this.loadingText = 'Retrieving Target Companies, please wait';
    if(this.mock){
      setTimeout(() => {
        this.getTargetCompanies();
      }, 0);
    }
    else{

      this.getTargetCompanies();
    }
  }

  getTargetCompanies(): void {
    if (this.mock) {
      // Mock response for target companies
      this.patentDetails = this.patentData.map((patent:any) => ({
        ...patent,
        companies: [
          {
            name: 'Alpha Corp.',
            products: []
          },
          {
            name: 'Beta Inc.',
            products: []
          }
        ]
      }));
      this.getCompanyInformation();
    } else {
      console.log(this.patentData)
      const requests = this.patentData.map((patent:any) =>
        this.http.post('/targetCompanies', { patentNumber: patent.patentNumber })
      );

      Promise.all(requests).then(responses => {
        this.patentDetails = responses.map((res: any, index: number) => ({
          // patentNumber: this.patentData[index].patentNumber,
          companies: res.targetCompanies.map((company: string) => ({ name: company, products: [] })),
          ...this.patentData[index]
        }));
      }).catch(error => {
        console.error('Error fetching target companies:', error);
      });
      this.getCompanyInformation();
    }
  }

  getCompanyInformation(){
    this.isLoading = true;
    this.loadingText = 'Retrieving Company Information, please wait';
     setTimeout(() => {
      this.getCompanyInfo();
    }, 2000);
  }

  getCompanyInfo(): void {
  if(this.mock)
  {
    /*"CompanyInformation": {

    "Global Headquarters": {

      "response": "",

      "sourceLink": ""

    },

    "US Headquarters": {

      "response": "",

      "sourceLink": ““

    },

    "State of Incorporation": {

      "response": "",

      "sourceLink": ""

    },

    "Revenue": {

      "response": "",

      "sourceLink": ""

    }

  }*/
//  this is the mock response for company information
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        console.log(patentInfo)
        company.companyInfo = {
          "Global Headquarters": {

            "response": "New York, NY",

            "sourceLink": "N/A"

          },

          "US Headquarters": {

            "response": " New York, NY",

            "sourceLink": "N/A"

          },

          "State of Incorporation": {

            "response": " Delaware",

            "sourceLink": "N/A"

          },

          "Revenue": {

            "response": "Revenue: $50-60 million USD",

            "sourceLink": "N/A"

          }
        };
      });
    });
    this.isLoading = false;
    this.currentStage = 2;
  }
  else {
    const requests = this.patentDetails.flatMap(patentInfo =>
      patentInfo.companies.map((company: any) =>
        this.http.post('/extractCompanyInformation', {
          patentNumber: patentInfo.patentNumber,
          companyName: company.name,
          modelName: 'perplexity-llama-3.1-sonar'
        })
      )
    );

    Promise.all(requests).then(responses => {
      console.log('response to api')
      let index = 0;
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company: any) => {
          console.log(responses[index].CompanyInformation)
          company.companyInfo = responses[index++].CompanyInformation;
        });
      });
    }).finally(()=>{
      this.isLoading = false;
      console.log('post api')
      this.currentStage = 2;
    })
    .catch(error => {
      console.error('Error fetching company information:', error);
      // this.isLoading = false;
    });
  }

  }

  getNewCompanyInfo(){
    console.log(this.patentDetails)
    let companyList:any=[]
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        if(company.new){
          companyList.push({patentNumber:patentInfo.patentNumber, companyName:company.name});
        }
      });
    });
    console.log(companyList)
  //  this would consist a list of patent number and company name. use the same logic but update on the same patent number and company name. this function would be making the api call. company list structure {patentNumber, companyName}
  this.isLoading = true;  
  this.loadingText = 'Retrieving Company Information, please wait';
  if(this.mock){
    // based on response, update the new companies. all new companies have new in their object.
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        if(company.new){
          company.companyInfo = {
            "Global Headquarters": {

              "response": "New York, NY",

              "sourceLink": "N/A"

            },

            "US Headquarters": {

              "response": " New York, NY",

              "sourceLink": "N/A"

            },

            "State of Incorporation": {

              "response": " Delaware",

              "sourceLink": "N/A"

            },

            "Revenue": {

              "response": "Revenue: $50-60 million USD",

              "sourceLink": "N/A"

            }
          };
          company.new=false
        }
      });
    });
    this.isLoading = false;
  }
  else 
  {
    const requests = companyList.map((company:any) =>
      this.http.post('/extractCompanyInformation', {
        patentNumber: company.patentNumber,
        companyName: company.companyName,
        modelName: 'perplexity-llama-3.1-sonar'
      })
    );

    // only update the company info for the company that was updated, match on the basis of patent number as well 
    Promise.all(requests).then(responses => {
      let index = 0;
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company: any) => {
          console.log(company, company.new)
          if(company.new){
            company.companyInfo = responses[index++].CompanyInformation;
          }
          company.new=false
        });

      });
      this.isLoading = false;
    }).catch(error => {
      console.error('Error fetching company information:', error);
      this.isLoading = false;
    });

  }

  }



  proceedToTargetProducts(): void {
    this.isLoading = true;
    this.loadingText = 'Retrieving Target Products, please wait';
    setTimeout(() => {
      this.getTargetProducts();
    }, 2000);
  }

  getTargetProducts(): void {
    if (this.mock) {
      // Mock response for target products
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company:any) => {
          company.products = [
            { name: 'Product A' },
            { name: 'Product B' }
          ];
        });
      });
      this.isLoading = false;
      this.currentStage = 3;
    } else {
      const requests = this.patentDetails.flatMap(patentInfo =>
        patentInfo.companies.map((company: any) =>
          this.http.post('/productInformation', {
            patentNumber: patentInfo.patentNumber,
            companyName: company?.name,
            modelName: 'perplexity-llama-3.1-sonar'
          })
        )
      );

      Promise.all(requests).then(responses => {
        let index = 0;
        this.patentDetails.forEach(patentInfo => {
          patentInfo.companies.forEach((company: any) => {
            company.products = responses[index++].ProductInformation['Targeted products'].map((product: any) => ({ name: product.response }));
          });
        });
      }).finally(()=>{
        this.isLoading = false;
        this.currentStage = 3;
      }).catch(error => {
        console.error('Error fetching target products:', error);
        this.isLoading = false;
      });
    }
  }

  proceedToRevenueIdentification(): void {
    this.isLoading = true;
    this.loadingText = 'Retrieving Revenue Details, please wait';
    setTimeout(() => {
      this.getRevenueDetails();
      this.titleText = 'Revenue Identification';
    }, 2000);
  }

  getRevenueDetails(): void {
    if (this.mock) {
      // Mock response for revenue details
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company:any) => {
          company.revenue = [
            {
              response: ' $50-60 million USD',
              sourceLink: 'N/A'
            }
          ];
          company.products.forEach((product: any) => {
            product.revenue = ' $5-10 million USD';
          });
        });
      });
      this.isLoading = false;
      this.currentStage = 4;
    } else {
      const requests = this.patentDetails.flatMap(patentInfo =>
        patentInfo.companies.map((company: any) =>
          this.http.post('/revenueInformation', {
            patentNumber: patentInfo.patentNumber,
            companyName: company.name,
            productList: company.products.map((product: any) => product.name),
            modelName: 'perplexity-llama-3.1-sonar'
          })
        )
      );

      Promise.all(requests).then(responses => {
        let index = 0;
        this.patentDetails.forEach(patentInfo => {
          patentInfo.companies.forEach((company: any) => {
            company.products.forEach((product: any) => {
              /*{

  "RevenueInformation":[ {

        "Verizon Media Smartplay Prebid": {

          "response": "Information not available",

          "sourceLink": "Information not available"

            }},{

        "Verizon Media DSP": {

          "response": "Information not available",

          "sourceLink": "Information not available"}

            }},{

        "Next-Gen Solutions": {

          "response": "Information not available",

          "sourceLink": "Information not available"

            }}

      }

]
}
  }*/
              // map the product revenue by matching the product name
              console.log(responses[index], product)
             responses[index].RevenueInformation["Product Revenue"].forEach((revenue:any) => {
              if(Object.keys(revenue)[0] === product.name){
                product.revenue = revenue[product.name]
                console.log(product)
              }
            });
          });
            index++;
        });
      })
    }).finally(()=>{
      this.isLoading = false;
      this.currentStage = 4;
    }).catch(error => {
        console.error('Error fetching revenue details:', error);
        this.isLoading = false;
      });
    }
  }

  toggleEditCompanies(): void {
    this.isEditingCompanies = !this.isEditingCompanies;
    if(this.isEditingCompanies){
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company:any) => {
          company.new = false;
        });
      });
    }
    else {
      this.getNewCompanyInfo();
    }
  }

  handleCompanyChange(event:any, company:any)
  {
    console.log(event, company)
    company.new=true;
  }

  addCompany(patent: any): void {
    patent.companies.push({ name: '', products: [], new:true });
  }

  confirmEditCompany(): void {
    this.isEditingCompanies = false;
    this.getNewCompanyInfo();
  }

  toggleEditProducts(): void {
    this.isEditingProducts = !this.isEditingProducts;
  }

  addProduct(company: any): void {
    company.products.push({ name: '' });
  }

  confirmEditProduct(): void {
    this.isEditingProducts = false;
  }
  deleteCompany(patent: any, companyIndex: number): void {
    patent.companies.splice(companyIndex, 1);
  }
  deleteProduct(company: any, productIndex: number): void {
    company.products.splice(productIndex, 1);
  }
  saveCompanies(): void {
    this.isEditingCompanies = false;
  }
  saveProducts(): void {
    this.isEditingProducts = false;
  }

  handlePopupSubmit(value: string): void {
    console.log('Value received from popup:', value);
  }
  openPopup(patentNumber:any, productName:any, companyName:any): void {
    const dialogRef = this.dialog.open(LinkInputPopupComponent, {
      width: '300px',
      data: { inputValue: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        console.log('Value received from popup:', result);
        this.handleInputPopup(result, patentNumber, productName, companyName);
      }
    });
  }

  assistMode=false;

  toggleAssistMode(){
    this.assistMode=!this.assistMode
  }

  handleInputPopup(value: string, patentNumber:any, productName:any, companyName:any): void {
    /*{

  "url": "https://en.wikipedia.org/wiki/Verizon",

  "patentNumber": "US6421675B1",

  "productName": "",

  "companyName": "Verizon Media Inc."

}

Response:

{

  "revenueInformationURLModel": {

    "response": "245.1 - 245.1 billion",

    "sourceLink": "https://www.microsoft.com/en-us/investor/earnings/fy-2024-q4/press-release-webcast"

  }*/
 // make this api call and update the revenue information for the product. if product is "", then you need to update the revenue information of the company
  this.isLoading = true;
  this.loadingText = 'Retrieving Revenue Details, please wait';
  this.http.post('/revenueURLInformation', {
    url: value,
    patentNumber: patentNumber,
    productName: productName,
    companyName: companyName
  }).then(response => {
    console.log(response, response.revenueInformationURLModel)
    if(productName === ""){
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company:any) => {
          if(company.name === companyName){
            company.revenue = [
              {
                response: response.revenueInformationURLModel.response,
                sourceLink: response.revenueInformationURLModel.sourceLink
              }
            ];
          }
        });
      });
    }
    else{
      this.patentDetails.forEach(patentInfo => {
        patentInfo.companies.forEach((company:any) => {
          if(company.name === companyName){
            company.products.forEach((product:any) => {
              if(product.name === productName){
                product.revenue = response.revenueInformationURLModel;
                console.log('updating product revenue:', product.revenue, response.revenueInformationURLModel.response);
                console.log(product)
              }
            });
          }
        });
      });
    }
    this.isLoading = false;
  }).catch(error => {
    console.error('Error fetching revenue details:', error);
    this.isLoading = false;
  });
  }


  openFileUploadPopup(patentNumber:any, productName:any, companyName:any): void {
    const dialogRef = this.dialog.open(FileUploadPopupComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        console.log('File received from popup:', result);
        this.handleProductRevenueFileUpload(result, patentNumber, productName, companyName);
      }
    });
  
}

  handleProductRevenueFileUpload(file:any, patentNumber:any, productName:any, companyName:any ){
    /*
    Endpoint: /revenuePDFInformation

Resquest: 

Body:

File

Parameters:

patentNumber

productName

companyName

Response:

{

  "revenueInformationPDFModel": {

    "response": "168,088 - 211,915 million",

    "sourceLink": "https://storage.googleapis.com/infringement_detector/revenue%20information/0000950170-23-035122.pdf"

  }

}*/
// make this api call and update the revenue information for the product. if product is "", then you need to update the revenue information of the company
this.isLoading = true;
this.loadingText = 'Retrieving Revenue Details, please wait';
// 
const formData=new FormData();
formData.append('file', file);
this.http.postFormData('/revenuePDFInformation?patentNumber',  formData, {
  params: {patentNumber: patentNumber, productName: productName, companyName: companyName}
}).then(response => {
  if(productName === ""){
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        console.log(company, companyName)
        if(company.name === companyName){
          company.companyInfo.Revenue = {
              response: response.revenueInformationPDFModel.response,
              sourceLink: response.revenueInformationPDFModel.sourceLink
            }
        }
      });
    });
  }
  else{
    this.patentDetails.forEach(patentInfo => {
      patentInfo.companies.forEach((company:any) => {
        if(company.name === companyName){
          company.products.forEach((product:any) => {
            console.log(product, productName)
            if(product.name === productName){
              product.revenue = response.revenueInformationPDFModel;
            }
          });
        }
      });
    });
  }
    this.isLoading = false;
})
  .catch(error => {
    console.error('Error fetching revenue details:', error);
    this.isLoading = false;
  });
  }

  showRevenueControls(company:any){
    if(company.showRevenue){

      company.showRevenue=false;
    }
    else{
      company.showRevenue=true;
    }
  }

  showProductRevenueControls(product:any){
    if(product.showRevenue){

      product.showRevenue=false;
    }
    else{
      product.showRevenue=true;
    }
  }
}
